const getCoordinatesFromZipcode = async (zipcode, city, state, country) => {
    const locationQuery = `${encodeURIComponent(zipcode)},${encodeURIComponent(city)},${encodeURIComponent(state)},${encodeURIComponent(country)}`;
    const url = `https://photon.komoot.io/api/?q=${locationQuery}&limit=1`;
    const response = await fetch(url);
    const data = await response.json();
    
    if (data.features.length > 0) {
        const { coordinates } = data.features[0].geometry;
        // console.log(`Found coordinates ${coordinates} for ${zipcode} in ${city}, ${state}, ${country}`);
        return { lat: coordinates[1], lng: coordinates[0] };
    } else {
        console.log(`No location found for zipcode: ${zipcode} in ${city}, ${state}, ${country}`);
    }
};

const getGeolocationByLocation = async (location) => {
    const response = await fetch(
        `https://photon.komoot.io/api/?q=${encodeURIComponent(location)}&limit=1`
    );
    const data = await response.json();
    
    if (data.features.length > 0) {
        const { coordinates } = data.features[0].geometry;
        return { lat: coordinates[1], lng: coordinates[0] };
    } else {
        console.log(`No location found for ${location}`);
        return null;
    }
};

export {
    getCoordinatesFromZipcode,
    getGeolocationByLocation
}
