// PromptContext.js
// React Imports
import React, { createContext, useState, useContext } from 'react';

// Create context
const PromptContext = createContext();

// Custom hook to use the PromptContext
export const usePrompt = () => useContext(PromptContext);

// Provider component
export const PromptProvider = ({ children }) => {
    const [userPrompt, setUserPrompt] = useState(null);
    const [systemPrompt, setSystemPrompt] = useState(null);

    return (
        <PromptContext.Provider value={{ userPrompt, setUserPrompt, systemPrompt, setSystemPrompt }}>
        {children}
        </PromptContext.Provider>
    );
};
