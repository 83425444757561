// NavBarLinksDashboard.js
// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
// Components
import Configurator from 'components/navbar/Configurator';
// Assets
import navImage from 'assets/img/layout/xeus_ai.png';
// Icons
import { MdInfoOutline } from 'react-icons/md';
import { MdFileDownload } from "react-icons/md";
// AuthContext
import { useAuth } from 'AuthContext';
// PDF Exporter Library
import jsPDF from 'jspdf';
import { toPng } from 'html-to-image';
import { useDateRange } from 'contexts/DateRangeContext';
import { format } from 'date-fns';
import { useLocation } from 'contexts/LocationContext';

export default function HeaderLinks(props) {
  const { secondary, theme, setTheme } = props;
  const { name, logout, brandText } = useAuth();
  const { location } = useLocation();
  const { dateRange } = useDateRange();

  let menuBg = useColorModeValue('white', 'navy.800');

  const navbarIcon = useColorModeValue('gray.400', 'white');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const { reportTemplateRef } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const exportAsPDF = async () => {
    onClose();
    await new Promise(resolve => setTimeout(resolve, 1000));

    // Scroll to top before capturing the entire content
    window.scrollTo(0, 0);

    // Capture the content as an image
    const dataUrl = await toPng(reportTemplateRef.current, {
      bgcolor: '#ffffff',  // Background color (optional)
      quality: 1.0,        // Full quality image
    });

    const pdf = new jsPDF('p', 'mm', 'a4');
    const padding = 10;  // Define padding in mm
    const imgWidth = 210 - 2 * padding;  // A4 width minus padding
    const pageHeight = 295 - 2 * padding;  // A4 height minus padding
    const imgHeight = (reportTemplateRef.current.scrollHeight * imgWidth) / reportTemplateRef.current.scrollWidth;  // Maintain aspect ratio

    // Add text before the image
    pdf.setFontSize(16);  // Set font size
    pdf.text(brandText, padding, 20);  // Add a title
    pdf.setFontSize(12);  // Set a smaller font size for additional text
    pdf.text(`${location} (${format(dateRange[0], 'MMMM d, yyyy')} - ${format(dateRange[1], 'MMMM d, yyyy')})`, padding, 30);  // Add a date

    // Add the image below the text
    let position = 40;  // Start position after the text
    pdf.addImage(dataUrl, 'PNG', padding, position, imgWidth, imgHeight);
    let heightLeft = imgHeight - (pageHeight - position);

    // If the image height exceeds one page, create more pages
    while (heightLeft > 0) {
      position = heightLeft - imgHeight + padding;
      pdf.addPage();
      pdf.addImage(dataUrl, 'PNG', padding, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    // Save the PDF
    pdf.save('download.pdf');
  };

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      borderRadius="999px"
      boxShadow={shadow}
    >      
      <Menu isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <MenuButton p="0px" pt="5px">
          <Icon
            as={MdFileDownload}
            color={navbarIcon}
            w="18px"
            h="18px"
            me="10px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="15px"
          me={{ base: '30px', md: 'unset' }}
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="17px"
          minW={{ base: 'unset' }}
          maxW={{ base: '360px', md: 'unset' }}
        >
          <Button
            w="100%"
            h="25px"
            variant="no-hover"
            color={textColor}
            bg="transparent"
            onClick={exportAsPDF}
          >
            Export as PDF
          </Button>
        </MenuList>
      </Menu>
      <Configurator
        mini={props.mini}
        setMini={props.setMini}
        theme={theme}
        setTheme={setTheme}
      />

      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: 'pointer' }}
            color="white"
            name={name}
            bg="#5158FF"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              ⚡&nbsp; Hey, { name }
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              color="red.400"
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm" onClick={logout}>Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
