// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   VStack,
//   Button,
//   Text,
//   Flex,
//   Divider,
//   useColorModeValue,
// } from '@chakra-ui/react';
// import { Scrollbars } from 'react-custom-scrollbars-2';
// import DateFilter from 'components/filters/DateFilter';
// import LocationFilter from 'components/filters/LocationFilter';
// // Import contexts
// import { useLocation } from 'contexts/LocationContext';
// import { useDateRange } from 'contexts/DateRangeContext';
// import { useFilteredData } from 'utils/dataHooks'; // Assuming you have this hook for filtering data

// const viewPathMap = {
//   'overview': 'overview/overview',
//   'meta_campaigns': 'marketing/meta_campaigns',
//   'google_campaigns': 'marketing/google_campaigns',
//   'tickets': 'tickets/tickets',
//   // ...other datasets
// };

// const systems = ['Data Scientist', 'Marketing Analyst', 'Economist', 'Software Engineer'];
// const users = ['Stakeholder', 'Teenager', 'Barista'];

// const DataSidebar = ({ setDisplayData }) => {
//   const [selectedDataset, setSelectedDataset] = useState(null);
//   const [selectedSystem, setSelectedSystem] = useState(null);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const { location } = useLocation(); // Get location from context
//   const { dateRange } = useDateRange(); // Get date range from context

//   const { filteredData } = useFilteredData(selectedDataset, location, dateRange); // Use selected dataset, location, and date range to filter data

//   const handleDatasetSelection = (dataset) => {
//     setSelectedDataset(dataset);
//   };

//   const handleSystemSelection = (system) => {
//     setSelectedSystem(system);
//   };

//   const handleUserSelection = (user) => {
//     setSelectedUser(user);
//   };

//   const generateUserPrompt = () => {
//     const dataContent = JSON.stringify(filteredData, null, 2); // Convert filtered data to a formatted JSON string
//     const content = [
//       {
//         text: `Analyze the dataset and highlight the key insights. Compare the results to any previous data and explain it like you are presenting the results to a ${selectedUser}.`
//       },
//       {
//         document: {
//           name: selectedDataset.replace(/_/g, ' '),
//           format: 'txt',
//           source: {
//             bytes: dataContent
//           }
//         }
//       }
//     ];

//     return [
//       {
//         role: 'user',
//         content
//       }
//     ];
//   };

//   const generateSystemPrompt = () => {
//     return [
//       {
//         text: `Please act like a seasoned ${selectedSystem} with a background in data analysis and marketing.`
//       }
//     ];
//   };

//   const handleFilterData = () => {
//     const userPrompt = generateUserPrompt();
//     const systemPrompt = generateSystemPrompt();

//     setDisplayData([
//       { type: 'user', message: JSON.stringify(userPrompt, null, 2) },
//       { type: 'system', message: JSON.stringify(systemPrompt, null, 2) }
//     ]);
//   };

//   const sidebarBg = useColorModeValue('white', 'navy.800');
//   const shadow = useColorModeValue(
//     '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
//     '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
//   );
//   const sidebarRadius = '30px';
//   const sidebarWidth = '285px';

//   return (
//     <Flex>
//       <Box
//         bg={sidebarBg}
//         w={sidebarWidth}
//         h="calc(100vh - 32px)"
//         m="16px"
//         borderRadius={sidebarRadius}
//         boxShadow={shadow}
//         overflowX="hidden"
//       >
//         <Scrollbars autoHide>
//           <Box p={4}>
//             {/* Top Section: Dataset Selection */}
//             <Text fontSize="xl" mb={4}>Select Dataset</Text>
//             <VStack align="start" mb={4}>
//               {Object.keys(viewPathMap).map((dataset) => (
//                 <Button
//                   key={dataset}
//                   colorScheme={selectedDataset === dataset ? 'blue' : 'gray'}
//                   onClick={() => handleDatasetSelection(dataset)}
//                   w="full"
//                 >
//                   {dataset.replace(/_/g, ' ')}
//                 </Button>
//               ))}
//             </VStack>

//             <Divider my={4} />

//             {/* Second Section: System Selection */}
//             <Text fontSize="xl" mb={4}>Select System</Text>
//             <VStack align="start" mb={4}>
//               {systems.map((system) => (
//                 <Button
//                   key={system}
//                   colorScheme={selectedSystem === system ? 'blue' : 'gray'}
//                   onClick={() => handleSystemSelection(system)}
//                   w="full"
//                 >
//                   {system}
//                 </Button>
//               ))}
//             </VStack>

//             <Divider my={4} />

//             {/* Third Section: User Selection */}
//             <Text fontSize="xl" mb={4}>Select User</Text>
//             <VStack align="start" mb={4}>
//               {users.map((user) => (
//                 <Button
//                   key={user}
//                   colorScheme={selectedUser === user ? 'blue' : 'gray'}
//                   onClick={() => handleUserSelection(user)}
//                   w="full"
//                 >
//                   {user}
//                 </Button>
//               ))}
//             </VStack>

//             <Divider my={4} />

//             {/* Fourth Section: Date and Location Filters */}
//             <Text fontSize="xl" mb={4}>Filters</Text>
//             <Box mb={4}>
//               <DateFilter />
//             </Box>
//             <Box mb={4}>
//               <LocationFilter />
//             </Box>

//             <Button
//               mt={4}
//               colorScheme="blue"
//               onClick={handleFilterData}
//               isDisabled={
//                 !selectedDataset ||
//                 !selectedSystem ||
//                 !selectedUser
//               }
//               w="full"
//             >
//               Generate Prompts
//             </Button>
//           </Box>
//         </Scrollbars>
//       </Box>
//     </Flex>
//   );
// };

// export default DataSidebar;

// // DataSidebar.js
// import React, { useState, useEffect } from 'react';
// import { Box, VStack, Button, Text, Flex, Divider, useColorModeValue } from '@chakra-ui/react';
// import { Scrollbars } from 'react-custom-scrollbars-2';
// import DateFilter from 'components/filters/DateFilter';
// import LocationFilter from 'components/filters/LocationFilter';
// import { useLocation } from 'contexts/LocationContext';
// import { useDateRange } from 'contexts/DateRangeContext';
// import { useFilteredData } from 'utils/dataHooks'; 
// import { usePrompt } from 'contexts/PromptContext'; // Import the prompt context

// const viewPathMap = {
//   'meta': 'meta',
//   'google': 'google',
//   'ticketing': 'ticketing',
// };

// const systems = ['Data Scientist', 'Marketing Analyst', 'Economist', 'Software Engineer'];
// const users = ['Stakeholder', 'Teenager', 'Barista'];

// const DataSidebar = () => {
//   const [selectedDataset, setSelectedDataset] = useState(null);
//   const [selectedSystem, setSelectedSystem] = useState(null);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const { location } = useLocation();
//   const { dateRange } = useDateRange();
//   const { filteredData } = useFilteredData(selectedDataset, location, dateRange);
//   const { setUserPrompt, setSystemPrompt } = usePrompt(); // Access context setters

//   const handleDatasetSelection = (dataset) => {
//     setSelectedDataset(dataset);
//   };

//   const handleSystemSelection = (system) => {
//     setSelectedSystem(system);
//   };

//   const handleUserSelection = (user) => {
//     setSelectedUser(user);
//   };

//   const generateUserPrompt = () => {
//     const dataContent = JSON.stringify(filteredData, null, 2); // Convert filtered data to JSON string
//     return [
//       {
//         role: `user`,
//         content: [
//           {
//             text: `Analyze the following dataset and highlight the key insights. Compare the results between periods (Current Period and Past Period) and explain your findings like you are presenting them to a ${selectedUser}. Here is the dataset: ${dataContent}`
//           }
//           // {
//           //   document: {
//           //     // name: selectedDataset.replace(/_/g, ' '),
//           //     name: "google",
//           //     format: "txt",
//           //     source: {
//           //       bytes: "[...]"
//           //     }
//           //   }
//           // }
//         ]
//       }
//     ];
//   };

//   const generateSystemPrompt = () => {
//     return [
//       {
//         text: `Please act like a seasoned ${selectedSystem} with a background in data analysis and marketing.`
//       }
//     ];
//   };

//   const handleFilterData = () => {
//     const userPrompt = generateUserPrompt();
//     const systemPrompt = generateSystemPrompt();

//     setUserPrompt(userPrompt); // Store in context
//     setSystemPrompt(systemPrompt); // Store in context

//     console.log(`You have selected:
//       Dataset: ${selectedDataset || 'None'}
//       Location: ${location || 'None'}
//       Date Range: ${dateRange ? `${dateRange.startDate} - ${dateRange.endDate}` : 'None'}
//       System: ${selectedSystem || 'None'}
//       User: ${selectedUser || 'None'}
//     `);
//   };

//   const sidebarBg = useColorModeValue('white', 'navy.800');
//   const shadow = useColorModeValue(
//     '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
//     '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
//   );
//   const sidebarRadius = '30px';
//   const sidebarWidth = '285px';

//   return (
//     <Flex>
//       <Box
//         bg={sidebarBg}
//         w={sidebarWidth}
//         h="calc(100vh - 32px)"
//         m="16px"
//         borderRadius={sidebarRadius}
//         boxShadow={shadow}
//         overflowX="hidden"
//       >
//         <Scrollbars autoHide>
//           <Box p={4}>
//             <Text fontSize="xl" mb={4}>Select Dataset</Text>
//             <VStack align="start" mb={4}>
//               {Object.keys(viewPathMap).map((dataset) => (
//                 <Button
//                   key={dataset}
//                   colorScheme={selectedDataset === dataset ? 'blue' : 'gray'}
//                   onClick={() => handleDatasetSelection(dataset)}
//                   w="full"
//                 >
//                   {dataset.replace(/_/g, ' ')}
//                 </Button>
//               ))}
//             </VStack>

//             <Divider my={4} />

//             <Text fontSize="xl" mb={4}>Select System</Text>
//             <VStack align="start" mb={4}>
//               {systems.map((system) => (
//                 <Button
//                   key={system}
//                   colorScheme={selectedSystem === system ? 'blue' : 'gray'}
//                   onClick={() => handleSystemSelection(system)}
//                   w="full"
//                 >
//                   {system}
//                 </Button>
//               ))}
//             </VStack>

//             <Divider my={4} />

//             <Text fontSize="xl" mb={4}>Select User</Text>
//             <VStack align="start" mb={4}>
//               {users.map((user) => (
//                 <Button
//                   key={user}
//                   colorScheme={selectedUser === user ? 'blue' : 'gray'}
//                   onClick={() => handleUserSelection(user)}
//                   w="full"
//                 >
//                   {user}
//                 </Button>
//               ))}
//             </VStack>

//             <Divider my={4} />

//             <Text fontSize="xl" mb={4} textAlign="center">Filters</Text>
//             <Flex direction="column" alignItems="center" mb={4}>
//               <Box mb={4} width="100%" display="flex" justifyContent="center">
//                 <DateFilter />
//               </Box>
//               <Box mb={4} width="100%" display="flex" justifyContent="center">
//                 <LocationFilter />
//               </Box>
//             </Flex>

//             <Button
//               mt={4}
//               colorScheme="blue"
//               onClick={handleFilterData}
//               isDisabled={!selectedDataset || !selectedSystem || !selectedUser}
//               w="full"
//             >
//               Confirm Selection
//             </Button>
//           </Box>
//         </Scrollbars>
//       </Box>
//     </Flex>
//   );
// };

// export default DataSidebar;


// DataSidebar.js
import React, { useState, useEffect } from 'react';
import { Box, VStack, Button, Text, Flex, Divider, useColorModeValue } from '@chakra-ui/react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DateFilter from 'components/filters/DateFilter';
import LocationFilter from 'components/filters/LocationFilter';
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
import { useFilteredData } from 'utils/dataHooks'; 
import { usePrompt } from 'contexts/PromptContext'; // Import the prompt context

const viewPathMap = {
  'meta': 'meta',
  'google': 'google',
  'ticketing': 'ticketing',
};

const systems = ['Data Scientist', 'Marketing Analyst', 'Economist', 'Software Engineer', 'None'];
const users = ['Stakeholder', 'Teenager', 'Grandmother', 'Senator', 'Musician', 'None'];

const DataSidebar = ({ onConfirmSelection }) => { // Accept the function as a prop
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedSystem, setSelectedSystem] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { filteredData } = useFilteredData(selectedDataset, location, dateRange);
  const { setUserPrompt, setSystemPrompt } = usePrompt(); // Access context setters

  // const formatDate = (date) => {
  //   const options = { year: 'numeric', month: 'long', day: 'numeric' };
  //   return date.toLocaleDateString(undefined, options);
  // };

  // const formattedDateRange = `${formatDate(dateRange[0])} - ${formatDate(dateRange[1])}`;

  console.log("location:", location)
  console.log("dateRange:", dateRange)
  console.log("startDate:", dateRange[0])
  // console.log("formattedDateRange:", formattedDateRange)
  console.log("filteredData:", filteredData)

  const handleDatasetSelection = (dataset) => {
    setSelectedDataset(dataset);
    console.log('Dataset selected:', dataset);
  };

  const handleSystemSelection = (system) => {
    setSelectedSystem(system);
    console.log('System selected:', system);
  };

  const handleUserSelection = (user) => {
    setSelectedUser(user);
    console.log('User selected:', user);
  };


  const generateUserPrompt = () => {
    const dataContent = JSON.stringify(filteredData, null, 2); // Convert filtered data to JSON string
  
    if (selectedUser === 'None') {
      // Dummy prompt when selectedUser is None
      return [
        {
          role: 'user',
          content: [
            {
              text: `Analyze the following dataset and highlight key insights. No user context is provided, so summarize the findings for a general audience. Focus on the differences between the Current Period and Past Period. Avoid using specific numbers. Here is the dataset: ${dataContent}`
            }
          ]
        }
      ];
    }
  
    // Standard prompt when selectedUser is not None
    return [
      {
        role: 'user',
        content: [
          {
            text: `Analyze the following dataset and highlight the key insights. Remember to compare the results between periods (Current Period and Past Period) but do not display any specific numbers. Your answer should include a brief analysis of each metric and a summary of the main findings that explains the big picture. You will apply your knowledge to help convey complex information like you are presenting your findings to a ${selectedUser}. Use language like you were conversing to a ${selectedUser}. Do not include any information about this prompt in your answer. Here is the dataset: ${dataContent}`
          }
        ]
      }
    ];
  };
  

  const generateSystemPrompt = () => {
    if (selectedSystem === 'None') {
      // Dummy prompt when selectedSystem is None
      return [
        {
          text: `Please act like an experienced generalist with a background in data analysis.`
        }
      ];
    }
  
    // Standard prompt when selectedSystem is not None
    return [
      {
        text: `Please act like a seasoned ${selectedSystem} with a background in data analysis and marketing.`
      }
    ];
  };
  

  const handleFilterData = () => {
    const userPrompt = generateUserPrompt();
    const systemPrompt = generateSystemPrompt();

    setUserPrompt(userPrompt); // Store in context
    setSystemPrompt(systemPrompt); // Store in context

    // const formattedDateRange = dateRange && dateRange.startDate && dateRange.endDate
    // ? `${dateRange.startDate} - ${dateRange.endDate}`
    // : 'None';

    const formatDate = (date) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    };
  
    const formattedDateRange = `${formatDate(dateRange[0])} - ${formatDate(dateRange[1])}`;

    // Call the passed function to display the confirmation message in Messages component
    if (onConfirmSelection) {
      onConfirmSelection(selectedDataset, location, dateRange, selectedSystem, selectedUser);
    }
  };

  const sidebarBg = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );
  const sidebarRadius = '30px';
  const sidebarWidth = '285px';

  return (
    <Flex h="100%" w="100%">
      <Box
        bg={sidebarBg}
        // w={sidebarWidth}
        w="100%"
        // h="calc(100vh - 32px)"
        h="100%"
        // m="16px"
        borderRadius={sidebarRadius}
        // boxShadow={shadow}
        overflowX="hidden"
        p="16px"
      >
        <Scrollbars autoHide>
          <Box p={4}>
            <Text fontSize="xl" mb={4}>Select Dataset</Text>
            <VStack align="start" mb={4}>
              {Object.keys(viewPathMap).map((dataset) => (
                <Button
                  key={dataset}
                  colorScheme={selectedDataset === dataset ? 'blue' : 'gray'}
                  onClick={() => handleDatasetSelection(dataset)}
                  w="full"
                >
                  {dataset.replace(/_/g, ' ')}
                </Button>
              ))}
            </VStack>

            <Divider my={4} />

            <Text fontSize="xl" mb={4}>Select System</Text>
            <VStack align="start" mb={4}>
              {systems.map((system) => (
                <Button
                  key={system}
                  colorScheme={selectedSystem === system ? 'blue' : 'gray'}
                  onClick={() => handleSystemSelection(system)}
                  w="full"
                >
                  {system}
                </Button>
              ))}
            </VStack>

            <Divider my={4} />

            <Text fontSize="xl" mb={4}>Select User</Text>
            <VStack align="start" mb={4}>
              {users.map((user) => (
                <Button
                  key={user}
                  colorScheme={selectedUser === user ? 'blue' : 'gray'}
                  onClick={() => handleUserSelection(user)}
                  w="full"
                >
                  {user}
                </Button>
              ))}
            </VStack>

            <Divider my={4} />

            <Text fontSize="xl" mb={4} textAlign="center">Filters</Text>
            <Flex direction="column" alignItems="center" mb={4}>
              <Box mb={4} width="100%" display="flex" justifyContent="center">
                <DateFilter />
              </Box>
              <Box mb={4} width="100%" display="flex" justifyContent="center">
                <LocationFilter />
              </Box>
            </Flex>

            <Button
              mt={4}
              colorScheme="blue"
              onClick={handleFilterData}
              isDisabled={!selectedDataset || !selectedSystem || !selectedUser}
              w="full"
            >
              Confirm Selection
            </Button>
          </Box>
        </Scrollbars>
      </Box>
    </Flex>
  );
};

export default DataSidebar;
