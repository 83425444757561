// // import {
// //   Box,
// //   Button,
// //   Flex,
// //   Image,
// //   Text,
// //   useColorModeValue,
// //   useDisclosure,
// //   Modal,
// //   ModalOverlay,
// //   ModalContent,
// //   ModalHeader,
// //   ModalCloseButton,
// //   ModalBody,
// //   ModalFooter,
// //   Heading,
// // } from "@chakra-ui/react";
// // // Custom components
// // import Card from "components/card/Card.js";
// // // Assets
// // import React, { useState } from "react";

// // import JsxParser from "react-jsx-parser";
// // import ApexCharts from "react-apexcharts";

// // export default function NFT(props) {
// //   const { image, name, location, dateRange, download, dateAdded, filePath } = props;
// //   const textColor = useColorModeValue("navy.700", "white");
// //   const { isOpen, onOpen, onClose } = useDisclosure();
// //   const [modelResponse, setModelResponse] = useState(null);
// //   const [parsedJsx, setParsedJsx] = useState("");
// //   const [parsedComponents, setParsedComponents] = useState({}); // Added this line

// //   const availableComponents = {
// //     ApexCharts,
// //     Box,
// //     Flex,
// //     Heading,
// //     Text,
// //   };
  
// //   const parseResponse = (responseText) => {
// //     // Extract the JSX section with a regex that handles multiline content
// //     const jsxRegex = /jsx=\{([\s\S]*)\}/;
// //     const jsxMatch = responseText.match(jsxRegex);
// //     let extractedJsx = jsxMatch ? jsxMatch[1].trim() : null;
  
// //     // Log the extracted JSX for debugging
// //     console.log("Extracted JSX:", extractedJsx);
  
// //     // Sanitize the extracted JSX
// //     let sanitizedExtractedJsx = extractedJsx.replace(/\\n/g, '').replace(/\\"/g, '"').trim();
  
// //     // Log the sanitized JSX for debugging
// //     console.log("Sanitized JSX:", sanitizedExtractedJsx);
  
// //     const locationMatch = responseText.match(/1\. Location:\s*([\s\S]*?)(?=\s*2\. Time Period:)/);
// //     const timePeriodMatch = responseText.match(/2\. Time Period:\s*([\s\S]*?)(?=\s*3\. Trends and Patterns:)/);
// //     const trendsAndPatternsMatch = responseText.match(/3\. Trends and Patterns:\s*([\s\S]*?)(?=\s*4\. Summary:)/);
// //     const summaryMatch = responseText.match(/4\. Summary:\s*([\s\S]*?)(?=\s*5\. Interpretation:)/);
// //     const interpretationMatch = responseText.match(/5\. Interpretation:\s*([\s\S]*?)(?=$)/);

// //     const sectionMap = {
// //       location: locationMatch ? locationMatch[1].trim() : undefined,
// //       timePeriod: timePeriodMatch ? timePeriodMatch[1].trim() : undefined,
// //       trendsAndPatterns: trendsAndPatternsMatch ? trendsAndPatternsMatch[1].trim() : undefined,
// //       summary: summaryMatch ? summaryMatch[1].trim() : undefined,
// //       interpretation: interpretationMatch ? interpretationMatch[1].trim() : undefined,
// //     };

// //     // Log each section for debugging
// //     console.log("Location Section:", sectionMap.location);
// //     console.log("Time Period Section:", sectionMap.timePeriod);
// //     console.log("Trends and Patterns Section:", sectionMap.trendsAndPatterns);
// //     console.log("Summary Section:", sectionMap.summary);
// //     console.log("Interpretation Section:", sectionMap.interpretation);
  
// //     return { parsedJsx: sanitizedExtractedJsx, sectionMap };
// //   };
  
// //   const handleSeeAnalysis = async () => {
// //     onOpen();
// //     try {
// //       const response = await fetch(filePath);
// //       const data = await response.text();
  
// //       console.log("Full Response Text:", data); // Log the entire response text
  
// //       const { parsedJsx } = parseResponse(data);
  
// //       setParsedJsx(parsedJsx);
// //     } catch (error) {
// //       console.error("Error fetching the analysis file:", error);
// //       setParsedJsx("Failed to load analysis.");
// //     }
// //   };
  

// //   return (
// //     <>
// //       <Card p="20px">
// //         <Flex direction={{ base: "column" }} justify="center">
// //           <Box mb={{ base: "20px", "2xl": "20px" }} position="relative">
// //             <Image
// //               src={image}
// //               w={{ base: "100%", "3xl": "100%" }}
// //               h={{ base: "100%", "3xl": "100%" }}
// //               borderRadius="20px"
// //             />
// //           </Box>
// //           <Flex flexDirection="column" justify="space-between" h="100%">
// //             <Flex
// //               justify="space-between"
// //               direction={{
// //                 base: "row",
// //                 md: "column",
// //                 lg: "row",
// //                 xl: "column",
// //                 "2xl": "row",
// //               }}
// //               mb="auto"
// //             >
// //               <Flex direction="column">
// //                 <Text
// //                   color={textColor}
// //                   fontSize={{
// //                     base: "xl",
// //                     md: "lg",
// //                     lg: "lg",
// //                     xl: "lg",
// //                     "2xl": "md",
// //                     "3xl": "lg",
// //                   }}
// //                   mb="5px"
// //                   fontWeight="bold"
// //                   me="14px"
// //                 >
// //                   {name}
// //                 </Text>
// //                 <Text
// //                   color="secondaryGray.600"
// //                   fontSize={{
// //                     base: "sm",
// //                   }}
// //                   fontWeight="400"
// //                   me="14px"
// //                 >
// //                   {dateRange}
// //                 </Text>
// //               </Flex>
// //             </Flex>
// //             <Flex
// //               align="start"
// //               justify="space-between"
// //               direction={{
// //                 base: "row",
// //                 md: "column",
// //                 lg: "row",
// //                 xl: "column",
// //                 "2xl": "row",
// //               }}
// //               mt="25px"
// //             >
// //               <Flex direction="column">
// //                 <Text
// //                   color={textColor}
// //                   fontSize={{
// //                     base: "12px",
// //                     md: "12px",
// //                     lg: "12px",
// //                     xl: "12px",
// //                     "2xl": "12px",
// //                     "3xl": "12px",
// //                   }}
// //                   mb="5px"
// //                   fontWeight="bold"
// //                   me="14px"
// //                 >
// //                   Date Added
// //                 </Text>
// //                 <Text
// //                   color="secondaryGray.600"
// //                   fontSize={{
// //                     base: "12px",
// //                     md: "12px",
// //                     lg: "12px",
// //                     xl: "12px",
// //                     "2xl": "12px",
// //                     "3xl": "12px",
// //                   }}
// //                   fontWeight="400"
// //                   me="14px"
// //                 >
// //                   {dateAdded}
// //                 </Text>
// //               </Flex>
// //               <Button
// //                 onClick={handleSeeAnalysis} // Attach the click handler to open the modal
// //                 mt={{
// //                   base: "0px",
// //                   md: "10px",
// //                   lg: "0px",
// //                   xl: "10px",
// //                   "2xl": "0px",
// //                 }}
// //                 variant="darkBrand"
// //                 color="white"
// //                 fontSize="sm"
// //                 fontWeight="500"
// //                 borderRadius="70px"
// //                 px="24px"
// //                 py="5px"
// //               >
// //                 See Analysis
// //               </Button>
// //             </Flex>
// //           </Flex>
// //         </Flex>
// //       </Card>

// //       {/* Modal for displaying the analysis result */}
// //       <Modal isOpen={isOpen} onClose={onClose} size="5xl">
// //     <ModalOverlay />
// //     <ModalContent>
// //       <ModalHeader>Analysis Result</ModalHeader>
// //       <ModalCloseButton />
// //       <ModalBody>
// //         {parsedJsx ? (
// //           <JsxParser components={availableComponents} jsx={parsedJsx} />
// //         ) : (
// //           <Text>Loading...</Text>
// //         )}
// //       </ModalBody>
// //       <ModalFooter>
// //         <Button colorScheme="blue" onClick={onClose}>
// //           Close
// //         </Button>
// //       </ModalFooter>
// //     </ModalContent>
// //   </Modal>
// //     </>
// //   );
// // }

// // import {
// //   Box,
// //   Button,
// //   Flex,
// //   Image,
// //   Text,
// //   useColorModeValue,
// //   useDisclosure,
// //   Modal,
// //   ModalOverlay,
// //   ModalContent,
// //   ModalHeader,
// //   ModalCloseButton,
// //   ModalBody,
// //   ModalFooter,
// //   Heading,
// // } from "@chakra-ui/react";
// // // Custom components
// // import Card from "components/card/Card.js";
// // // Assets
// // import React, { useState } from "react";

// // import JsxParser from "react-jsx-parser";
// // import ApexCharts from "react-apexcharts";

// // export default function NFT(props) {
// //   const { image, name, location, dateRange, download, dateAdded, filePath } = props;
// //   const textColor = useColorModeValue("navy.700", "white");
// //   const { isOpen, onOpen, onClose } = useDisclosure();
// //   const [modelResponse, setModelResponse] = useState(null);
// //   const [parsedJsx, setParsedJsx] = useState("");
// //   const [sectionMap, setSectionMap] = useState({});

// //   const availableComponents = {
// //     ApexCharts,
// //     Box,
// //     Flex,
// //     Heading,
// //     Text,
// //   };

// //   const parseResponse = (responseText) => {
// //     const jsxRegex = /jsx=\{([\s\S]*)\}/;
// //     const jsxMatch = responseText.match(jsxRegex);
// //     let extractedJsx = jsxMatch ? jsxMatch[1].trim() : null;

// //     let sanitizedExtractedJsx = extractedJsx.replace(/\\n/g, '').replace(/\\"/g, '"').trim();

// //     const locationMatch = responseText.match(/1\. Location:\s*([\s\S]*?)(?=\s*2\. Time Period:)/);
// //     const timePeriodMatch = responseText.match(/2\. Time Period:\s*([\s\S]*?)(?=\s*3\. Trends and Patterns:)/);
// //     const trendsAndPatternsMatch = responseText.match(/3\. Trends and Patterns:\s*([\s\S]*?)(?=\s*4\. Summary:)/);
// //     const summaryMatch = responseText.match(/4\. Summary:\s*([\s\S]*?)(?=\s*5\. Interpretation:)/);
// //     const interpretationMatch = responseText.match(/5\. Interpretation:\s*([\s\S]*?)(?=$)/);

// //     const sectionMap = {
// //       location: locationMatch ? locationMatch[1].trim() : undefined,
// //       timePeriod: timePeriodMatch ? timePeriodMatch[1].trim() : undefined,
// //       trendsAndPatterns: trendsAndPatternsMatch ? trendsAndPatternsMatch[1].trim() : undefined,
// //       summary: summaryMatch ? summaryMatch[1].trim() : undefined,
// //       interpretation: interpretationMatch ? interpretationMatch[1].trim() : undefined,
// //     };

// //     return { parsedJsx: sanitizedExtractedJsx, sectionMap };
// //   };

// //   const handleSeeAnalysis = async () => {
// //     onOpen();
// //     try {
// //       const response = await fetch(filePath);
// //       const data = await response.text();

// //       const { parsedJsx, sectionMap } = parseResponse(data);

// //       setParsedJsx(parsedJsx);
// //       setSectionMap(sectionMap);
// //     } catch (error) {
// //       console.error("Error fetching the analysis file:", error);
// //       setParsedJsx("Failed to load analysis.");
// //     }
// //   };

// //   return (
// //     <>
// //       <Card p="20px">
// //         <Flex direction={{ base: "column" }} justify="center">
// //           <Box mb={{ base: "20px", "2xl": "20px" }} position="relative">
// //             <Image
// //               src={image}
// //               w={{ base: "100%", "3xl": "100%" }}
// //               h={{ base: "100%", "3xl": "100%" }}
// //               borderRadius="20px"
// //             />
// //           </Box>
// //           <Flex flexDirection="column" justify="space-between" h="100%">
// //             <Flex
// //               justify="space-between"
// //               direction={{
// //                 base: "row",
// //                 md: "column",
// //                 lg: "row",
// //                 xl: "column",
// //                 "2xl": "row",
// //               }}
// //               mb="auto"
// //             >
// //               <Flex direction="column">
// //                 <Text
// //                   color={textColor}
// //                   fontSize={{
// //                     base: "xl",
// //                     md: "lg",
// //                     lg: "lg",
// //                     xl: "lg",
// //                     "2xl": "md",
// //                     "3xl": "lg",
// //                   }}
// //                   mb="5px"
// //                   fontWeight="bold"
// //                   me="14px"
// //                 >
// //                   {name}
// //                 </Text>
// //                 <Text
// //                   color="secondaryGray.600"
// //                   fontSize={{
// //                     base: "sm",
// //                   }}
// //                   fontWeight="400"
// //                   me="14px"
// //                 >
// //                   {dateRange}
// //                 </Text>
// //               </Flex>
// //             </Flex>
// //             <Flex
// //               align="start"
// //               justify="space-between"
// //               direction={{
// //                 base: "row",
// //                 md: "column",
// //                 lg: "row",
// //                 xl: "column",
// //                 "2xl": "row",
// //               }}
// //               mt="25px"
// //             >
// //               <Flex direction="column">
// //                 <Text
// //                   color={textColor}
// //                   fontSize={{
// //                     base: "12px",
// //                     md: "12px",
// //                     lg: "12px",
// //                     xl: "12px",
// //                     "2xl": "12px",
// //                     "3xl": "12px",
// //                   }}
// //                   mb="5px"
// //                   fontWeight="bold"
// //                   me="14px"
// //                 >
// //                   Date Added
// //                 </Text>
// //                 <Text
// //                   color="secondaryGray.600"
// //                   fontSize={{
// //                     base: "12px",
// //                     md: "12px",
// //                     lg: "12px",
// //                     xl: "12px",
// //                     "2xl": "12px",
// //                     "3xl": "12px",
// //                   }}
// //                   fontWeight="400"
// //                   me="14px"
// //                 >
// //                   {dateAdded}
// //                 </Text>
// //               </Flex>
// //               <Button
// //                 onClick={handleSeeAnalysis} // Attach the click handler to open the modal
// //                 mt={{
// //                   base: "0px",
// //                   md: "10px",
// //                   lg: "0px",
// //                   xl: "10px",
// //                   "2xl": "0px",
// //                 }}
// //                 variant="darkBrand"
// //                 color="white"
// //                 fontSize="sm"
// //                 fontWeight="500"
// //                 borderRadius="70px"
// //                 px="24px"
// //                 py="5px"
// //               >
// //                 See Analysis
// //               </Button>
// //             </Flex>
// //           </Flex>
// //         </Flex>
// //       </Card>

// //       {/* Modal for displaying the analysis result */}
// //       <Modal isOpen={isOpen} onClose={onClose} size="5xl">
// //         <ModalOverlay />
// //         <ModalContent>
// //           <ModalHeader>Analysis Result</ModalHeader>
// //           <ModalCloseButton />
// //           <ModalBody>
// //             {/* Render sections 1 to 4 */}
// //             <Text>{sectionMap.location}</Text>
// //             <Text>{sectionMap.timePeriod}</Text>
// //             <Text>{sectionMap.trendsAndPatterns}</Text>
// //             <Text>{sectionMap.summary}</Text>

// //             {/* Render the chart */}
// //             {parsedJsx ? (
// //               <JsxParser components={availableComponents} jsx={parsedJsx} />
// //             ) : (
// //               <Text>Loading...</Text>
// //             )}

// //             {/* Render section 5 */}
// //             <Text mt={5}>{sectionMap.interpretation}</Text>
// //           </ModalBody>
// //           <ModalFooter>
// //             <Button colorScheme="blue" onClick={onClose}>
// //               Close
// //             </Button>
// //           </ModalFooter>
// //         </ModalContent>
// //       </Modal>
// //     </>
// //   );
// // }


// import {
//   Box,
//   Button,
//   Flex,
//   Image,
//   Text,
//   useColorModeValue,
//   useDisclosure,
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalCloseButton,
//   ModalBody,
//   ModalFooter,
//   Heading,
//   Divider,
// } from "@chakra-ui/react";
// // Custom components
// import Card from "components/card/Card.js";
// // Assets
// import React, { useState } from "react";

// import JsxParser from "react-jsx-parser";
// import ApexCharts from "react-apexcharts";

// export default function NFT(props) {
//   const { color, name, location, dateRange, download, dateAdded, filePath } = props;
//   const textColor = useColorModeValue("navy.700", "white");
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const [modelResponse, setModelResponse] = useState(null);
//   const [parsedJsx, setParsedJsx] = useState("");
//   const [sectionMap, setSectionMap] = useState({});

//   const availableComponents = {
//     ApexCharts,
//     Box,
//     Flex,
//     Heading,
//     Text,
//   };

//   const parseResponse = (responseText) => {
//     const jsxRegex = /jsx=\{([\s\S]*)\}/;
//     const jsxMatch = responseText.match(jsxRegex);
//     let extractedJsx = jsxMatch ? jsxMatch[1].trim() : null;

//     let sanitizedExtractedJsx = extractedJsx.replace(/\\n/g, '').replace(/\\"/g, '"').trim();

//     const cleanText = (text) => {
//       return text ? text.replace(/\\n/g, '\n').trim() : '';
//     };

//     const locationMatch = responseText.match(/1\. Location:\s*([\s\S]*?)(?=\s*2\. Time Period:)/);
//     const timePeriodMatch = responseText.match(/2\. Time Period:\s*([\s\S]*?)(?=\s*3\. Trends and Patterns:)/);
//     const trendsAndPatternsMatch = responseText.match(/3\. Trends and Patterns:\s*([\s\S]*?)(?=\s*4\. Summary:)/);
//     const summaryMatch = responseText.match(/4\. Summary:\s*([\s\S]*?)(?=\s*5\. Interpretation:)/);
//     const interpretationMatch = responseText.match(/5\. Interpretation:\s*([\s\S]*?)(?=$)/);

//     const sectionMap = {
//       location: cleanText(locationMatch ? locationMatch[1].trim() : undefined),
//       timePeriod: cleanText(timePeriodMatch ? timePeriodMatch[1].trim() : undefined),
//       trendsAndPatterns: cleanText(trendsAndPatternsMatch ? trendsAndPatternsMatch[1].trim() : undefined),
//       summary: cleanText(summaryMatch ? summaryMatch[1].trim() : undefined),
//       interpretation: cleanText(interpretationMatch ? interpretationMatch[1].trim() : undefined),
//     };

//     return { parsedJsx: sanitizedExtractedJsx, sectionMap };
//   };

//   const handleSeeAnalysis = async () => {
//     onOpen();
//     try {
//       const response = await fetch(filePath);
//       const data = await response.text();

//       const { parsedJsx, sectionMap } = parseResponse(data);

//       setParsedJsx(parsedJsx);
//       setSectionMap(sectionMap);
//     } catch (error) {
//       console.error("Error fetching the analysis file:", error);
//       setParsedJsx("Failed to load analysis.");
//     }
//   };

//   return (
//     <>
//       <Card p="20px">
//         <Flex direction={{ base: "column" }} justify="center">
//           <Box mb={{ base: "20px", "2xl": "20px" }} position="relative">
//             <Box
//               bgGradient={color}
//               w={{ base: "100%", "3xl": "100%" }}
//               h={{ base: "200px", "3xl": "150px" }}
//               borderRadius="20px"
//             ></Box>
//           </Box>
//           <Flex flexDirection="column" justify="space-between" h="100%">
//             <Flex
//               justify="space-between"
//               direction={{
//                 base: "row",
//                 md: "column",
//                 lg: "row",
//                 xl: "column",
//                 "2xl": "row",
//               }}
//               mb="auto"
//             >
//               <Flex direction="column">
//                 <Text
//                   color={textColor}
//                   fontSize={{
//                     base: "xl",
//                     md: "lg",
//                     lg: "lg",
//                     xl: "lg",
//                     "2xl": "md",
//                     "3xl": "lg",
//                   }}
//                   mb="5px"
//                   fontWeight="bold"
//                   me="14px"
//                 >
//                   {name}
//                 </Text>
//                 <Text
//                   color="secondaryGray.600"
//                   fontSize={{
//                     base: "sm",
//                   }}
//                   fontWeight="400"
//                   me="14px"
//                 >
//                   {dateRange}
//                 </Text>
//               </Flex>
//             </Flex>
//             <Flex
//               align="start"
//               justify="space-between"
//               direction={{
//                 base: "row",
//                 md: "column",
//                 lg: "row",
//                 xl: "column",
//                 "2xl": "row",
//               }}
//               mt="25px"
//             >
//               <Flex direction="column">
//                 <Text
//                   color={textColor}
//                   fontSize={{
//                     base: "12px",
//                     md: "12px",
//                     lg: "12px",
//                     xl: "12px",
//                     "2xl": "12px",
//                     "3xl": "12px",
//                   }}
//                   mb="5px"
//                   fontWeight="bold"
//                   me="14px"
//                 >
//                   Date Added
//                 </Text>
//                 <Text
//                   color="secondaryGray.600"
//                   fontSize={{
//                     base: "12px",
//                     md: "12px",
//                     lg: "12px",
//                     xl: "12px",
//                     "2xl": "12px",
//                     "3xl": "12px",
//                   }}
//                   fontWeight="400"
//                   me="14px"
//                 >
//                   {dateAdded}
//                 </Text>
//               </Flex>
//               <Button
//                 onClick={handleSeeAnalysis}
//                 mt={{
//                   base: "0px",
//                   md: "10px",
//                   lg: "0px",
//                   xl: "10px",
//                   "2xl": "0px",
//                 }}
//                 variant="darkBrand"
//                 color="white"
//                 fontSize="sm"
//                 fontWeight="500"
//                 borderRadius="70px"
//                 px="24px"
//                 py="5px"
//               >
//                 See Analysis
//               </Button>
//             </Flex>
//           </Flex>
//         </Flex>
//       </Card>

//       {/* Modal for displaying the analysis result */}
//       {/* Modal for displaying the analysis result */}
// {/* Modal for displaying the analysis result */}
// {/* Modal for displaying the analysis result */}
// {/* Modal for displaying the analysis result */}
// <Modal isOpen={isOpen} onClose={onClose} size="5xl">
//   <ModalOverlay />
//   <ModalContent>
//     {/* Header Section */}
//     <Box bgGradient="linear(to-r, blue.500, purple.500)" p={6} borderTopRadius="lg">
//       <Heading color="white" size="lg" whiteSpace="pre-wrap">
//         {sectionMap.location}
//       </Heading>
//       <Text color="white" mt={2} whiteSpace="pre-wrap">
//         {sectionMap.timePeriod}
//       </Text>
//     </Box>
    
//     <ModalCloseButton color="white" />

//     <ModalBody bg="gray.900" color="white">
//       {/* Middle Section */}
//       <Box p={4} bg="gray.800" borderRadius="md" mb={6} maxW="80%" mx="auto" textAlign="center">
//       <Heading size="md" mb={2}>
//           Summary
//         </Heading>
//         <Text mb={4} whiteSpace="pre-wrap">
//           {sectionMap.summary}
//         </Text>

//         {/* Render the chart */}
//         {parsedJsx ? (
//           <JsxParser components={availableComponents} jsx={parsedJsx} />
//         ) : (
//           <Text>Loading...</Text>
//         )}
//       </Box>

//       {/* Bottom Section */}
//       <Box p={4} bg="gray.800" borderRadius="md" mb={4}>
//         <Heading size="md" mb={2}>
//           Trends and Patterns
//         </Heading>
//         <Text whiteSpace="pre-wrap">
//           {sectionMap.trendsAndPatterns}
//         </Text>
//       </Box>

//       <Box p={4} bg="gray.800" borderRadius="md">
//         <Heading size="md" mb={2}>
//           Interpretation
//         </Heading>
//         <Text whiteSpace="pre-wrap">
//           {sectionMap.interpretation}
//         </Text>
//       </Box>
//     </ModalBody>

//     <ModalFooter>
//       <Button colorScheme="blue" onClick={onClose}>
//         Close
//       </Button>
//     </ModalFooter>
//   </ModalContent>
// </Modal>




//     </>
//   );
// }


// import {
//   Box,
//   Button,
//   Flex,
//   Text,
//   useColorModeValue,
//   useDisclosure,
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalCloseButton,
//   ModalBody,
//   ModalFooter,
//   Heading,
// } from "@chakra-ui/react";
// import React, { useState } from "react";
// import Card from "components/card/Card.js";

// export default function NFT(props) {
//   const { color, name, dateRange, dateAdded, filePath } = props;
//   const textColor = useColorModeValue("navy.700", "white");
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const [fileContent, setFileContent] = useState("");

//   const handleSeeAnalysis = async () => {
//     onOpen();
//     try {
//       const response = await fetch(filePath);
//       const data = await response.text();
//       setFileContent(data); // Set the content of the text file
//     } catch (error) {
//       console.error("Error fetching the analysis file:", error);
//       setFileContent("Failed to load analysis.");
//     }
//   };

//   return (
//     <>
//       <Card p="20px">
//         <Flex direction={{ base: "column" }} justify="center">
//           <Box mb={{ base: "20px", "2xl": "20px" }} position="relative">
//             <Box
//               bgGradient={color}
//               w={{ base: "100%", "3xl": "100%" }}
//               h={{ base: "200px", "3xl": "150px" }}
//               borderRadius="20px"
//             ></Box>
//           </Box>
//           <Flex flexDirection="column" justify="space-between" h="100%">
//             <Flex justify="space-between" mb="auto">
//               <Flex direction="column">
//                 <Text
//                   color={textColor}
//                   fontSize={{ base: "xl", md: "lg", lg: "lg" }}
//                   mb="5px"
//                   fontWeight="bold"
//                 >
//                   {name}
//                 </Text>
//                 <Text
//                   color="secondaryGray.600"
//                   fontSize="sm"
//                   fontWeight="400"
//                 >
//                   {dateRange}
//                 </Text>
//               </Flex>
//             </Flex>
//             <Flex align="start" mt="25px">
//               <Flex direction="column">
//                 <Text
//                   color={textColor}
//                   fontSize="12px"
//                   mb="5px"
//                   fontWeight="bold"
//                 >
//                   Date Added
//                 </Text>
//                 <Text
//                   color="secondaryGray.600"
//                   fontSize="12px"
//                   fontWeight="400"
//                 >
//                   {dateAdded}
//                 </Text>
//               </Flex>
//               <Button
//                 onClick={handleSeeAnalysis}
//                 mt="10px"
//                 variant="darkBrand"
//                 color="white"
//                 fontSize="sm"
//                 fontWeight="500"
//                 borderRadius="70px"
//                 px="24px"
//                 py="5px"
//               >
//                 See Summary
//               </Button>
//             </Flex>
//           </Flex>
//         </Flex>
//       </Card>

//       {/* Modal for displaying the analysis result */}
//       <Modal isOpen={isOpen} onClose={onClose} size="5xl">
//         <ModalOverlay />
//         <ModalContent>
//           <ModalHeader>Summary</ModalHeader>
//           <ModalCloseButton />
//           <ModalBody>
//             <Box whiteSpace="pre-wrap">{fileContent}</Box> {/* Display text file content */}
//           </ModalBody>
//           <ModalFooter>
//             <Button colorScheme="blue" onClick={onClose}>
//               Close
//             </Button>
//           </ModalFooter>
//         </ModalContent>
//       </Modal>
//     </>
//   );
// }


import {
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Heading,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Card from "components/card/Card.js";

export default function NFT(props) {
  const { color, name, dateRange, dateAdded, summary } = props;
  const textColor = useColorModeValue("navy.700", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fileContent, setFileContent] = useState("");

  const handleSeeAnalysis = async () => {
    onOpen();
    try {
      // Since summary already contains the data, you can set it directly
      const data = summary;
      setFileContent(data); // Set the content of the text file
    } catch (error) {
      console.error("Error processing the analysis data:", error);
      setFileContent("Failed to load analysis.");
    }
  };

  return (
    <>
      <Card p="20px">
        <Flex direction={{ base: "column" }} justify="center">
          <Box mb={{ base: "20px", "2xl": "20px" }} position="relative">
            <Box
              bgGradient={color}
              w={{ base: "100%", "3xl": "100%" }}
              h={{ base: "200px", "3xl": "150px" }}
              borderRadius="20px"
            ></Box>
          </Box>
          <Flex flexDirection="column" justify="space-between" h="100%">
            <Flex justify="space-between" mb="auto">
              <Flex direction="column">
                <Text
                  color={textColor}
                  fontSize={{ base: "xl", md: "lg", lg: "lg" }}
                  mb="5px"
                  fontWeight="bold"
                >
                  {name}
                </Text>
                <Text
                  color="secondaryGray.600"
                  fontSize="sm"
                  fontWeight="400"
                >
                  {dateRange}
                </Text>
              </Flex>
            </Flex>
            <Flex align="start" mt="25px">
              <Flex direction="column">
                <Text
                  color={textColor}
                  fontSize="12px"
                  mb="5px"
                  fontWeight="bold"
                >
                  Date Added
                </Text>
                <Text
                  color="secondaryGray.600"
                  fontSize="12px"
                  fontWeight="400"
                >
                  {dateAdded}
                </Text>
              </Flex>
              <Button
                onClick={handleSeeAnalysis}
                mt="10px"
                variant="darkBrand"
                color="white"
                fontSize="sm"
                fontWeight="500"
                borderRadius="70px"
                px="24px"
                py="5px"
                ml="auto"  // This pushes the button to the right
              >
                See Summary
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Card>

      {/* Modal for displaying the analysis result */}
      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          {/* Keep the header section */}
          <Box bgGradient="linear(to-r, blue.500, purple.500)" p={6} borderTopRadius="lg">
            <Heading color="white" size="lg">
              {name}
            </Heading>
            <Text color="white" mt={2}>
              {dateRange}
            </Text>
          </Box>

          <ModalCloseButton color="white" />

          <ModalBody bg="gray.900" color="white">
            {/* Display the file content */}
            <Box p={4} bg="gray.800" borderRadius="md" mb={4} whiteSpace="pre-wrap">
              {fileContent}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
