import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  SimpleGrid,
  Select,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";

import { SearchBar } from "./components/Search";
import NFT from "components/card/NFT";
import { HSeparator } from "components/separator/Separator";

import {
  MdDashboard,
  MdApps,
  MdOutlineCollections,
} from "react-icons/md";

import {dummyNFTs} from "variables/dummyNFTs.js";
// Data Functions
import { useAPIData } from 'utils/dataHooks';

export default function Default() {
  const [tabState, setTabState] = useState("meta");
  const [sortOrder, setSortOrder] = useState("newest"); // For dateAdded sorting
  const [nameOrder, setNameOrder] = useState("a_to_z"); // For name sorting
  const [viewMode, setViewMode] = useState("grid"); // To switch between grid and list view
  const [searchQuery, setSearchQuery] = useState("");

  const [nftData, setNftData] = useState({});

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const buttonBg = useColorModeValue("transparent", "navy.800");
  const hoverButton = useColorModeValue({ bg: "gray.100" }, { bg: "whiteAlpha.100" });
  const activeButton = useColorModeValue({ bg: "gray.200" }, { bg: "whiteAlpha.200" });
  const paleGray = useColorModeValue("secondaryGray.400", "whiteAlpha.100");

  const sortNFTs = (nfts) => {
    let sortedNFTs = [...nfts];

    sortedNFTs.sort((a, b) => {
        // Sort by dateAdded first
        let dateComparison = new Date(b.dateAdded) - new Date(a.dateAdded);
        if (sortOrder === "oldest") {
            dateComparison = new Date(a.dateAdded) - new Date(b.dateAdded);
        }

        // If dates are equal, sort by name
        if (dateComparison === 0) {
            if (nameOrder === "a_to_z") {
                return a.name.localeCompare(b.name);
            } else {
                return b.name.localeCompare(a.name);
            }
        }

        return dateComparison;
    });

    return sortedNFTs;
  };

  // Fetch data for all NFTs on component mount or when `tabState` changes
  useEffect(() => {
    const fetchNFTData = async () => {
      const dataMap = {};
  
      for (const nft of dummyNFTs[tabState]) {
        const formattedDateRange = nft.dateRangeFilter.replace(/[\[\]"]/g, '').replace(/,\s*/g, '_');
        try {
          const response = await fetch(
            `http://localhost:3003/data/${nft.tableNameFilter}__${encodeURIComponent(nft.locationFilter)}__${formattedDateRange}`,
            {
              headers: {
                'Company': 'beat_the_bomb',
              },
            }
          );
  
          // Read the response as plain text instead of JSON
          const textData = await response.text();
          dataMap[nft.name] = textData;
        } catch (error) {
          console.error(`Error fetching data for ${nft.name}:`, error);
          dataMap[nft.name] = "Error fetching data";
        }
      }
  
      setNftData(dataMap);
    };
  
    fetchNFTData();
  }, [tabState]);
  
  const renderNFTs = () => {
    const filteredNFTs = dummyNFTs[tabState].filter((nft) =>
      nft.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  
    const nfts = sortNFTs(filteredNFTs);
  
    return nfts.map((nft, index) => (
        <NFT
          key={index}
          name={nft.name}
          dateRange={nft.dateRange}
          color={nft.color}
          dateAdded={nft.dateAdded}
          summary={nftData[nft.name] || "Loading..."} // Display the fetched text data
        />
      ));
  };
  

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      {/* <Box mb='20px' display={{ base: "block", lg: "grid" }}>
        <Flex flexDirection='column'>
          <Banner
            bgGradient='linear(#2577F9 15%, #12AFE4 70%, #04DBD5)'
            profile={MdDashboard}
            name='Xeus AI'
            floor={0.56}
            volume={33.8}
            owners={4.6}
            items={28}
          />
        </Flex>
      </Box> */}
      <Tabs variant='soft-rounded' colorScheme='brandTabs'>
        <TabList mx={{ base: "10px", lg: "30px" }} overflowX={{ sm: "scroll", lg: "unset" }}>
          <Flex justify={{ base: "start", md: "center" }} w='100%'>
            {Object.keys(dummyNFTs).map((key) => (
              <Tab
                key={key}
                pb='0px'
                flexDirection='column'
                onClick={() => setTabState(key)}
                me='50px'
                bg='unset'
                _selected={{ bg: "none" }}
                _focus={{ border: "none" }}
                minW='max-content'>
                <Flex align='center'>
                  <Icon color={textColor} as={MdOutlineCollections} w='20px' h='20px' me='8px' />
                  <Text color={textColor} fontSize='lg' fontWeight='500' me='12px'>
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </Text>
                  <Text color='secondaryGray.600' fontSize='md' fontWeight='400'>
                    {dummyNFTs[key].length}
                  </Text>
                </Flex>
                <Box
                  height='4px'
                  w='100%'
                  transition='0.1s linear'
                  bg={tabState === key ? "brand.500" : "transparent"}
                  mt='15px'
                  borderRadius='20px'
                />
              </Tab>
            ))}
          </Flex>
        </TabList>
        <HSeparator mb='30px' bg={paleGray} mt='0px' />
        <Flex w='100%' mb='25px'>
        <SearchBar searchQuery={searchQuery} onSearchChange={setSearchQuery} />
        <Select
            fontSize='sm'
            variant='main'
            h='44px'
            maxh='44px'
            me='20px'
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
          >
            <option value='newest'>Newest to Oldest</option>
            <option value='oldest'>Oldest to Newest</option>
          </Select>
          <Select
            fontSize='sm'
            variant='main'
            h='44px'
            maxh='44px'
            me='20px'
            value={nameOrder}
            onChange={(e) => setNameOrder(e.target.value)}
          >
            <option value='a_to_z'>A to Z</option>
            <option value='z_to_a'>Z to A</option>
          </Select>
          <Button
            me='20px'
            bg={viewMode === "grid" ? buttonBg : "gray.300"}
            border='1px solid'
            color='secondaryGray.600'
            borderColor={useColorModeValue("secondaryGray.100", "whiteAlpha.100")}
            borderRadius='16px'
            _placeholder={{ color: "secondaryGray.600" }}
            _hover={hoverButton}
            _active={activeButton}
            _focus={activeButton}
            onClick={() => setViewMode("grid")}
          >
            <Icon color={textColor} as={MdDashboard} />
          </Button>
          <Button
            bg={viewMode === "list" ? buttonBg : "gray.300"}
            border='1px solid'
            color='secondaryGray.600'
            borderColor={useColorModeValue("secondaryGray.100", "whiteAlpha.100")}
            borderRadius='16px'
            _placeholder={{ color: "secondaryGray.600" }}
            _hover={hoverButton}
            _active={activeButton}
            _focus={activeButton}
            onClick={() => setViewMode("list")}
          >
            <Icon color={textColor} as={MdApps} />
          </Button>
        </Flex>
        <Text mt='25px' mb='36px' color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
          {dummyNFTs[tabState].length} Results
        </Text>
        <SimpleGrid columns={{ sm: 1, md: viewMode === "grid" ? 2 : 1, xl: viewMode === "grid" ? 4 : 1 }} spacing='24px'>
          {renderNFTs()}
        </SimpleGrid>
      </Tabs>
    </Box>
  );
}
