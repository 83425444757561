// DateFilter.js
// React / Chakra Imports
import React from 'react';
import {
  Icon,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  useDisclosure,
  useColorModeValue,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
// Date Imports
import { format, addDays } from 'date-fns';
// Icons
import { FaCalendarAlt } from 'react-icons/fa';
// Components
import MiniCalendar from "components/calendar/MiniCalendar";
// Contexts
import { useDateRange } from 'contexts/DateRangeContext';

export default function Banner() {
  const { dateRange, setDateRange, pastDateRange, currentMode, setCurrentMode, selectedDateRangeOption, setSelectedDateRangeOption } = useDateRange();

  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue("secondaryGray.500", "white");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgList = useColorModeValue("white", "whiteAlpha.100");
  const bgBackground = useColorModeValue('white', 'navy.800');
  const bgShadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const handleRadioChange = (value) => {
    let newDateRange = [];
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    const dayOfWeek = today.getDay();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  
    switch (value) {
      case 'today':
        newDateRange = [today, today];
        break;
      case 'yesterday':
        const yesterday = addDays(today, -1);
        newDateRange = [yesterday, yesterday];
        break;
      case 'last7days':
        newDateRange = [addDays(today, -6), today];
        break;
      case 'last14days':
        newDateRange = [addDays(today, -13), today];
        break;
      case 'last30days':
        newDateRange = [addDays(today, -29), today];
        break;
      case 'thisWeek':
        const startOfThisWeek = addDays(today, -dayOfWeek);
        const endOfThisWeek = today;
        newDateRange = [startOfThisWeek, endOfThisWeek];
        break;
      case 'lastWeek':
        const startOfLastWeek = addDays(today, -dayOfWeek - 7);
        const endOfLastWeek = addDays(startOfLastWeek, 6);
        newDateRange = [startOfLastWeek, endOfLastWeek];
        break;
      case 'thisMonth':
        newDateRange = [startOfMonth, today];
        break;
      case 'lastMonth':
        const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
        newDateRange = [startOfLastMonth, endOfLastMonth];
        break;
      case 'custom':
        // For custom, you might want to handle this with the MiniCalendar component
        return;
      default:
        return;
    }
  
    setSelectedDateRangeOption(value);
    setDateRange(newDateRange);
  };
  

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      bg={menuBg}
      ml="10px"
      p="10px"
      borderRadius="999px"
      boxShadow={shadow}
    >
      <Menu isOpen={isOpen1} onClose={onClose1}>
        <MenuButton
          align='center'
          justifyContent='center'
          ml="20px"
          mr="20px"
          _hover={bgHover}
          w='auto'
          h='37px'
          lineHeight='100%'
          onClick={onOpen1}
          borderRadius='10px'
        >
          <Flex align='center' justifyContent="center">
            <Icon as={FaCalendarAlt} color={iconColor} w='14px' h='24px' />
            <Text ml='8px' fontSize='sm' color={textColor}>
              {format(dateRange[0], 'MMMM d, yyyy')} - {format(dateRange[1], 'MMMM d, yyyy')}
            </Text>
          </Flex>
        </MenuButton>
        <MenuList
          w="450px"
          minW="unset"
          maxW="450px !important"
          border="transparent"
          backdropFilter="blur(63px)"
          bg={bgList}
          boxShadow={bgShadow}
          borderRadius="20px"
          p="15px"
        >
          <Flex direction="row" justifyContent="space-between">
            <RadioGroup
              onChange={handleRadioChange}
              value={selectedDateRangeOption}
              w="35%"
              bg={bgBackground}
              borderRadius="3xl"
              p="10px"
              mr="10px"
              minH="400px"
            >
              <Flex direction="column" justifyContent="space-around" h="100%">
                <Radio colorScheme="teal" value="yesterday" size="sm">Yesterday</Radio>
                <Radio colorScheme="teal" value="today" size="sm">Today</Radio>
                <Radio colorScheme="teal" value="last7days" size="sm">Last 7 Days</Radio>
                <Radio colorScheme="teal" value="last14days" size="sm">Last 14 Days</Radio>
                <Radio colorScheme="teal" value="last30days" size="sm">Last 30 Days</Radio>
                <Radio colorScheme="teal" value="thisWeek" size="sm">This Week</Radio>
                <Radio colorScheme="teal" value="lastWeek" size="sm">Last Week</Radio>
                <Radio colorScheme="teal" value="thisMonth" size="sm">This Month</Radio>
                <Radio colorScheme="teal" value="lastMonth" size="sm">Last Month</Radio>
                <Radio colorScheme="teal" value="custom" size="sm">Custom</Radio>
              </Flex>
            </RadioGroup>
            <MiniCalendar
              selectRange={true}
              onChange={handleDateChange}
              dateRange={dateRange}
              pastDateRange={pastDateRange}
              currentMode={currentMode}
              setCurrentMode={setCurrentMode}
              w="65%"
            />
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
