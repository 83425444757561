// Import necessary dependencies
import React, { useState, useEffect } from 'react';
import {
  Box,
  Checkbox,
  VStack,
  Button,
  Text,
  Flex,
  Divider,
  useColorModeValue,
} from '@chakra-ui/react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useFilteredData } from 'utils/dataHooks';
import DateFilter from 'components/filters/DateFilter';
import LocationFilter from 'components/filters/LocationFilter';
// Import contexts
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';

const viewPathMap = {
  'meta': 'meta',
  'google': 'google',
  'ticketing': 'ticketing',
};

const FilterPage = () => {
  const [selectedDataset, setSelectedDataset] = useState('overview'); // Default dataset
  const { location } = useLocation(); // Get location from context
  const { dateRange } = useDateRange(); // Get date range from context

  const { filteredData } = useFilteredData(selectedDataset, location, dateRange); // Use the selected dataset

  const [fields, setFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [displayData, setDisplayData] = useState([]);

  // Extract unique fields from the dataset
  useEffect(() => {
    if (filteredData.length > 0) {
      const uniqueFields = Object.keys(filteredData[0]);
      setFields(uniqueFields);
    }
  }, [filteredData]);

  // Handle dataset selection
  const handleDatasetSelection = (dataset) => {
    setSelectedDataset(dataset);
    setSelectedFields([]); // Clear selected fields when dataset changes
    setDisplayData([]); // Clear display data when dataset changes
  };

  // Handle checkbox changes
  const handleFieldSelection = (field) => {
    setSelectedFields((prevSelected) =>
      prevSelected.includes(field)
        ? prevSelected.filter((f) => f !== field)
        : [...prevSelected, field]
    );
  };

  // Filter data based on selected fields
  const handleFilterData = () => {
    const filtered = filteredData.map((item) =>
      selectedFields.reduce((obj, key) => {
        if (item[key] !== undefined) {
          obj[key] = item[key];
        }
        return obj;
      }, {})
    );
    setDisplayData(filtered); // Set the state with the filtered data
  };

  // Sidebar styling
  const sidebarBg = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );
  const sidebarRadius = '30px';
  const sidebarWidth = '285px';

  return (
    <Flex>
      {/* Sidebar for selection and filters */}
      <Box
        bg={sidebarBg}
        w={sidebarWidth}
        h="calc(100vh - 32px)"
        m="16px"
        borderRadius={sidebarRadius}
        boxShadow={shadow}
        overflowX="hidden"
      >
        <Scrollbars autoHide>
          <Box p={4}>
            {/* Top Section: Dataset Selection */}
            <Text fontSize="xl" mb={4}>Select Dataset</Text>
            <VStack align="start" mb={4}>
              {Object.keys(viewPathMap).map((dataset) => (
                <Button
                  key={dataset}
                  colorScheme={selectedDataset === dataset ? 'blue' : 'gray'}
                  onClick={() => handleDatasetSelection(dataset)}
                  w="full"
                >
                  {dataset.replace(/_/g, ' ')}
                </Button>
              ))}
            </VStack>

            <Divider my={4} />

            {/* Middle Section: Field Selection */}
            <Text fontSize="xl" mb={4}>Select Fields</Text>
            <VStack align="start" mb={4}>
              {fields.map((field) => (
                <Checkbox
                  key={field}
                  isChecked={selectedFields.includes(field)}
                  onChange={() => handleFieldSelection(field)}
                >
                  {field}
                </Checkbox>
              ))}
            </VStack>
            <Button
              mt={4}
              colorScheme="blue"
              onClick={handleFilterData}
              isDisabled={selectedFields.length === 0}
              w="full"
            >
              Display Data
            </Button>

            <Divider my={4} />

            {/* Bottom Section: Date and Location Filters */}
            <Text fontSize="xl" mb={4}>Filters</Text>
            <Box mb={4}>
              <DateFilter />
            </Box>
            <Box mb={4}>
              <LocationFilter />
            </Box>
          </Box>
        </Scrollbars>
      </Box>

      {/* Main content to display filtered data */}
      <Box flex="1" p={4}>
        <Text fontSize="2xl" mb={4}>Filtered Data:</Text>
        {displayData.length > 0 ? (
          <pre>{JSON.stringify(displayData, null, 2)}</pre>
        ) : (
          <Text>No data to display. Select fields and click "Display Data".</Text>
        )}
      </Box>
    </Flex>
  );
};

export default FilterPage;