export const dummyNFTs = {
    // overview: [
    //   // { 
    //   //   name: 'Atlanta',
    //   //   locationFilter: 'Atlanta',
    //   //   tableNameFilter: 'overview',
    //   //   dateRangeFilter: '["2024-08-26", "2024-09-01"]',
    //   //   dateRange: 'August 26, 2024 - September 1, 2024',
    //   //   color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
    //   //   dateAdded: '2024-09-02'
    //   // },
    //   // { 
    //   //   name: 'Brooklyn',
    //   //   locationFilter: 'Brooklyn',
    //   //   tableNameFilter: 'overview',
    //   //   dateRangeFilter: '["2024-08-26", "2024-09-01"]',
    //   //   dateRange: 'August 26, 2024 - September 1, 2024',
    //   //   color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
    //   //   dateAdded: '2024-09-02'
    //   // },
    //   // { 
    //   //   name: 'Washington',
    //   //   locationFilter: 'Washington',
    //   //   tableNameFilter: 'overview',
    //   //   dateRangeFilter: '["2024-08-26", "2024-09-01"]',
    //   //   dateRange: 'August 26, 2024 - September 1, 2024',
    //   //   color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
    //   //   dateAdded: '2024-09-02'
    //   // },
    //   { 
    //     name: 'Atlanta',
    //     locationFilter: 'Atlanta',
    //     tableNameFilter: 'overview',
    //     dateRangeFilter: '["2024-09-02", "2024-09-08"]',
    //     dateRange: 'September 2, 2024 - September 8, 2024',
    //     color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
    //     dateAdded: '2024-09-09'
    //   },
    //   { 
    //     name: 'Brooklyn',
    //     locationFilter: 'Brooklyn',
    //     tableNameFilter: 'overview',
    //     dateRangeFilter: '["2024-09-02", "2024-09-08"]',
    //     dateRange: 'September 2, 2024 - September 8, 2024',
    //     color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
    //     dateAdded: '2024-09-09'
    //   },
    //   { 
    //     name: 'Washington',
    //     locationFilter: 'Washington',
    //     tableNameFilter: 'overview',
    //     dateRangeFilter: '["2024-09-02", "2024-09-08"]',
    //     dateRange: 'September 2, 2024 - September 8, 2024',
    //     color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
    //     dateAdded: '2024-09-09' 
    //   },
    // ],
    meta: [
      // { 
      //   name: 'Atlanta',
      //   locationFilter: 'Atlanta',
      //   tableNameFilter: 'meta',
      //   dateRangeFilter: '["2024-08-26", "2024-09-01"]',
      //   dateRange: 'August 26, 2024 - September 1, 2024',
      //   color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
      //   dateAdded: '2024-09-02'
      // },
      // { 
      //   name: 'Brooklyn',
      //   locationFilter: 'Brooklyn',
      //   tableNameFilter: 'meta',
      //   dateRangeFilter: '["2024-08-26", "2024-09-01"]',
      //   dateRange: 'August 26, 2024 - September 1, 2024',
      //   color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
      //   dateAdded: '2024-09-02'
      // },
      // { 
      //   name: 'Washington',
      //   locationFilter: 'Washington',
      //   tableNameFilter: 'meta',
      //   dateRangeFilter: '["2024-08-26", "2024-09-01"]',
      //   dateRange: 'August 26, 2024 - September 1, 2024',
      //   color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
      //   dateAdded: '2024-09-02'
      // },
      { 
        name: 'Atlanta',
        locationFilter: 'Atlanta',
        tableNameFilter: 'meta',
        dateRangeFilter: '["2024-09-02", "2024-09-08"]',
        dateRange: 'September 2, 2024 - September 8, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-09'
      },
      { 
        name: 'Brooklyn',
        locationFilter: 'Brooklyn',
        tableNameFilter: 'meta',
        dateRangeFilter: '["2024-09-02", "2024-09-08"]',
        dateRange: 'September 2, 2024 - September 8, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-09-09'
      },
      { 
        name: 'Washington',
        locationFilter: 'Washington',
        tableNameFilter: 'meta',
        dateRangeFilter: '["2024-09-02", "2024-09-08"]',
        dateRange: 'September 2, 2024 - September 8, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-09' 
      },
    ],
    google: [
      // { 
      //   name: 'Atlanta',
      //   locationFilter: 'Atlanta',
      //   tableNameFilter: 'google',
      //   dateRangeFilter: '["2024-08-26", "2024-09-01"]',
      //   dateRange: 'August 26, 2024 - September 1, 2024',
      //   color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
      //   dateAdded: '2024-09-02'
      // },
      // { 
      //   name: 'Brooklyn',
      //   locationFilter: 'Brooklyn',
      //   tableNameFilter: 'google',
      //   dateRangeFilter: '["2024-08-26", "2024-09-01"]',
      //   dateRange: 'August 26, 2024 - September 1, 2024',
      //   color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
      //   dateAdded: '2024-09-02'
      // },
      // { 
      //   name: 'Washington',
      //   locationFilter: 'Washington',
      //   tableNameFilter: 'google',
      //   dateRangeFilter: '["2024-08-26", "2024-09-01"]',
      //   dateRange: 'August 26, 2024 - September 1, 2024',
      //   color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
      //   dateAdded: '2024-09-02'
      // },
      { 
        name: 'Atlanta',
        locationFilter: 'Atlanta',
        tableNameFilter: 'google',
        dateRangeFilter: '["2024-09-02", "2024-09-08"]',
        dateRange: 'September 2, 2024 - September 8, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-09'
      },
      { 
        name: 'Brooklyn',
        locationFilter: 'Brooklyn',
        tableNameFilter: 'google',
        dateRangeFilter: '["2024-09-02", "2024-09-08"]',
        dateRange: 'September 2, 2024 - September 8, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-09-09'
      },
      { 
        name: 'Washington',
        locationFilter: 'Washington',
        tableNameFilter: 'google',
        dateRangeFilter: '["2024-09-02", "2024-09-08"]',
        dateRange: 'September 2, 2024 - September 8, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-09' 
      },
    ],
    ticketing: [
      { 
        name: 'Atlanta',
        locationFilter: 'Atlanta',
        tableNameFilter: 'ticketing',
        dateRangeFilter: '["2024-09-02", "2024-09-08"]',
        dateRange: 'September 2, 2024 - September 8, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-09'
      },
      { 
        name: 'Brooklyn',
        locationFilter: 'Brooklyn',
        tableNameFilter: 'ticketing',
        dateRangeFilter: '["2024-09-02", "2024-09-08"]',
        dateRange: 'September 2, 2024 - September 8, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-09-09'
      },
      { 
        name: 'Washington',
        locationFilter: 'Washington',
        tableNameFilter: 'ticketing',
        dateRangeFilter: '["2024-09-02", "2024-09-08"]',
        dateRange: 'September 2, 2024 - September 8, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-09' 
      },
    ],
  };
  