import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  SimpleGrid,
  Select,
  Tabs,
  TabList,
  Tab,
} from "@chakra-ui/react";

import { SearchBar } from "./components/Search";
import Summary from "./components/Summary";
import { HSeparator } from "components/separator/Separator";

import {
  MdDashboard,
  MdApps,
  MdOutlineCollections,
  MdHome, MdSettings, MdAccountCircle
} from "react-icons/md";

import { FaMeta } from "react-icons/fa6";
import { SiGoogleads } from "react-icons/si";
import { FaTicket } from "react-icons/fa6";

import { summaryCards } from "variables/summaryCards";

export default function Default() {
  const [tabState, setTabState] = useState("ticketing");
  const [sortOrder, setSortOrder] = useState("newest"); // For dateAdded sorting
  const [nameOrder, setNameOrder] = useState("a_to_z"); // For name sorting
  const [viewMode, setViewMode] = useState("grid"); // To switch between grid and list view
  const [searchQuery, setSearchQuery] = useState("");
  const company = sessionStorage.getItem('company') || localStorage.getItem('company');

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const buttonBg = useColorModeValue("transparent", "navy.800");
  const hoverButton = useColorModeValue({ bg: "gray.100" }, { bg: "whiteAlpha.100" });
  const activeButton = useColorModeValue({ bg: "gray.200" }, { bg: "whiteAlpha.200" });
  const paleGray = useColorModeValue("secondaryGray.400", "whiteAlpha.100");

  const sortSummaries = (summary) => {
    let sortedSummaries = [...summary];

    sortedSummaries.sort((a, b) => {
        // Sort by dateAdded first
        let dateComparison = new Date(b.dateAdded) - new Date(a.dateAdded);
        if (sortOrder === "oldest") {
            dateComparison = new Date(a.dateAdded) - new Date(b.dateAdded);
        }

        // If dates are equal, sort by name
        if (dateComparison === 0) {
            if (nameOrder === "a_to_z") {
                return a.locationFilter.localeCompare(b.locationFilter);
            } else {
                return b.locationFilter.localeCompare(a.locationFilter);
            }
        }

        return dateComparison;
    });

    return sortedSummaries;
  };

  const renderSummary = () => {
    const filteredSummary = summaryCards[company][tabState].filter((summary) =>
      summary.locationFilter.toLowerCase().includes(searchQuery.toLowerCase())
    );
  
    const summaries = sortSummaries(filteredSummary);
  
    return summaries.map((summary, index) => (
        <Summary
          key={index}
          tabKey={summary.tabKey}
          // name={summary.name}
          clientFilter={summary.clientFilter}
          tableNameFilter={summary.tableNameFilter}
          locationFilter={summary.locationFilter}
          currentDateRangeFilter={summary.currentDateRangeFilter}
          pastDateRangeFilter={summary.pastDateRangeFilter}
          currentDateRange={summary.currentDateRange}
          pastDateRange={summary.pastDateRange}
          dateAdded={summary.dateAdded}
          color={summary.color}
        />
      ));
  };
  
  const iconMapping = {
    ticketing: FaTicket,
    meta: FaMeta,
    google: SiGoogleads,
    default: MdOutlineCollections, // fallback icon
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Tabs variant='soft-rounded' colorScheme='brandTabs'>
        {/* <TabList mx={{ base: "10px", lg: "30px" }} overflowX={{ sm: "scroll", lg: "unset" }}>
          <Flex justify={{ base: "start", md: "center" }} w='100%'>
            {Object.keys(summaryCards[company]).map((key) => (
              <Tab
                key={key}
                pb='0px'
                flexDirection='column'
                onClick={() => setTabState(key)}
                me='50px'
                bg='unset'
                _selected={{ bg: "none" }}
                _focus={{ border: "none" }}
                minW='max-content'>
                <Flex align='center'>
                  <Icon color={textColor} as={MdOutlineCollections} w='20px' h='20px' me='8px' />
                  <Text color={textColor} fontSize='lg' fontWeight='500' me='12px'>
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </Text>
                  <Text color='secondaryGray.600' fontSize='md' fontWeight='400'>
                    {summaryCards[company][key].length}
                  </Text>
                </Flex>
                <Box
                  height='4px'
                  w='100%'
                  transition='0.1s linear'
                  bg={tabState === key ? "brand.500" : "transparent"}
                  mt='15px'
                  borderRadius='20px'
                />
              </Tab>
            ))}
          </Flex>
        </TabList> */}
        <TabList mx={{ base: "10px", lg: "30px" }} overflowX={{ sm: "scroll", lg: "unset" }}>
          <Flex justify={{ base: "start", md: "center" }} w='100%'>
            {Object.keys(summaryCards[company]).map((key) => {
              const IconComponent = iconMapping[key] || iconMapping.default;
              
              return (
                <Tab
                  key={key}
                  pb='0px'
                  flexDirection='column'
                  onClick={() => setTabState(key)}
                  me='50px'
                  bg='unset'
                  _selected={{ bg: "none" }}
                  _focus={{ border: "none" }}
                  minW='max-content'>
                  <Flex align='center'>
                    <Icon color={textColor} as={IconComponent} w='20px' h='20px' me='8px' />
                    <Text color={textColor} fontSize='lg' fontWeight='500' me='12px'>
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </Text>
                    <Text color='secondaryGray.600' fontSize='md' fontWeight='400'>
                      {summaryCards[company][key].length}
                    </Text>
                  </Flex>
                  <Box
                    height='4px'
                    w='100%'
                    transition='0.1s linear'
                    bg={tabState === key ? "brand.500" : "transparent"}
                    mt='15px'
                    borderRadius='20px'
                  />
                </Tab>
              );
            })}
          </Flex>
        </TabList>
        <HSeparator mb='30px' bg={paleGray} mt='0px' />
        <Flex w='100%' mb='25px'>
        <SearchBar searchQuery={searchQuery} onSearchChange={setSearchQuery} />
        <Select
            fontSize='sm'
            variant='main'
            h='44px'
            maxh='44px'
            me='20px'
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
          >
            <option value='newest'>Newest to Oldest</option>
            <option value='oldest'>Oldest to Newest</option>
          </Select>
          <Select
            fontSize='sm'
            variant='main'
            h='44px'
            maxh='44px'
            me='20px'
            value={nameOrder}
            onChange={(e) => setNameOrder(e.target.value)}
          >
            <option value='a_to_z'>A to Z</option>
            <option value='z_to_a'>Z to A</option>
          </Select>
          <Button
            me='20px'
            bg={viewMode === "grid" ? buttonBg : "gray.300"}
            border='1px solid'
            color='secondaryGray.600'
            borderColor={useColorModeValue("secondaryGray.100", "whiteAlpha.100")}
            borderRadius='16px'
            _placeholder={{ color: "secondaryGray.600" }}
            _hover={hoverButton}
            _active={activeButton}
            _focus={activeButton}
            onClick={() => setViewMode("grid")}
          >
            <Icon color={textColor} as={MdDashboard} />
          </Button>
          <Button
            bg={viewMode === "list" ? buttonBg : "gray.300"}
            border='1px solid'
            color='secondaryGray.600'
            borderColor={useColorModeValue("secondaryGray.100", "whiteAlpha.100")}
            borderRadius='16px'
            _placeholder={{ color: "secondaryGray.600" }}
            _hover={hoverButton}
            _active={activeButton}
            _focus={activeButton}
            onClick={() => setViewMode("list")}
          >
            <Icon color={textColor} as={MdApps} />
          </Button>
        </Flex>
        <Text mt='25px' mb='36px' color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
          {summaryCards[company][tabState].length} Results
        </Text>
        <SimpleGrid columns={{ sm: 1, md: viewMode === "grid" ? 2 : 1, xl: viewMode === "grid" ? 4 : 1 }} spacing='24px'>
          {renderSummary()}
        </SimpleGrid>
      </Tabs>
    </Box>
  );
}