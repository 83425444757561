// // Messages.js
// import React, { useState, useEffect, useRef } from "react";
// import {
//   Avatar,
//   Box,
//   Button,
//   Flex,
//   Icon,
//   IconButton,
//   Input,
//   InputGroup,
//   InputRightElement,
//   Text,
//   useColorModeValue,
//   useDisclosure,
//   Menu,
//   MenuButton,
//   MenuItem,
//   MenuList,
// } from "@chakra-ui/react";
// import {
//   messagesRenderThumb,
//   messagesRenderTrack,
//   messagesRenderView,
// } from "components/scrollbar/Scrollbar";
// import { Scrollbars } from "react-custom-scrollbars-2";
// import { FaCircle } from "react-icons/fa";
// import { FiSearch } from "react-icons/fi";
// import { MdDeleteForever } from "react-icons/md";
// import { IoPaperPlane } from "react-icons/io5";
// import {
//   MdOutlineMoreVert,
//   MdAttachFile,
//   MdOutlineImage,
//   MdOutlineCardTravel,
// } from "react-icons/md";
// import { LightningChatIconFlex } from "components/icons/Icons";
// import { usePrompt } from 'contexts/PromptContext'; // Import the prompt context
// import { invokeModel } from "utils/api";
// import CryptoJS from "crypto-js";
// import AnimatedIcon from "components/icons/AnimatedIcons";

// export default function Messages({
//   dataConfirmationMessage, // Added dataset confirmation message
//   modelConfirmationMessage, // Added model confirmation message
//   messageData = [],
//   ...props
// }) {
//   const { status, name, onSendMessage, ...rest } = props;
//   const { datasetPrompt, setDatasetPrompt, modelPrompt, systemPrompt, audiencePrompt, userPrompt, setUserPrompt, sessionId, setSessionId } = usePrompt(); // Add setSessionId here
//   const [newMessage, setNewMessage] = useState("");
//   const [localMessageData, setLocalMessageData] = useState(messageData); // Local state to manage message data
//   const [isLoading, setIsLoading] = useState(false);
//   const [dotCount, setDotCount] = useState(0); // Track the number of dots
//   const scrollbarsRef = useRef(null);

//   const textColor = useColorModeValue("secondaryGray.900", "white");
//   const inputColor = useColorModeValue("secondaryGray.700", "secondaryGray.700");
//   const inputText = useColorModeValue("gray.700", "gray.100");
//   const blockBg = useColorModeValue("secondaryGray.300", "navy.700");
//   const userMessageBg = useColorModeValue("purple.500", "purple.500");
//   const brandButton = useColorModeValue("brand.500", "brand.400");
//   const bgInput = useColorModeValue(
//     "linear-gradient(1.02deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%)",
//     "linear-gradient(1.02deg, #111C44 50%, rgba(17, 28, 68, 0) 100%)"
//   );

//   const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
//   const textHover = useColorModeValue(
//     { color: "secondaryGray.900", bg: "unset" },
//     { color: "secondaryGray.500", bg: "unset" }
//   );
//   const bgList = useColorModeValue("white", "whiteAlpha.100");
//   const bgShadow = useColorModeValue(
//     "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
//     "unset"
//   );
//   const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");

//   // Function to generate session ID based on current date
//   const generateSessionId = () => {
//     const currentDate = new Date().toISOString().replace(/[-:.TZ]/g, "");
//     const hash = CryptoJS.SHA256(currentDate).toString(CryptoJS.enc.Hex);
//     return hash.slice(0, 12); // Return first 12 characters of the hash
//   };

//   useEffect(() => {
//     // Generate sessionId if not already present
//     if (!sessionId) {
//       const newSessionId = generateSessionId();
//       setSessionId(newSessionId); // Update sessionId in context
//     }
//   }, [sessionId, setSessionId]);

//   const addConfirmationMessage = (confirmationMessage, type) => {
//     if (confirmationMessage) {
//       const newMessage = {
//         isUserMessage: false,
//         content: `${type} Confirmation:\n${confirmationMessage}`,
//         time: new Date(),
//       };

//       setLocalMessageData((prevMessages) => [...prevMessages, newMessage]);
//     }
//   };

//   // Automatically add the dataset confirmation message when it changes
//   useEffect(() => {
//     if (dataConfirmationMessage) {
//       addConfirmationMessage(dataConfirmationMessage, "Dataset");
//     }
//   }, [dataConfirmationMessage]);

//   // Automatically add the model confirmation message when it changes
//   useEffect(() => {
//     if (modelConfirmationMessage) {
//       addConfirmationMessage(modelConfirmationMessage, "Model");
//     }
//   }, [modelConfirmationMessage]);

//   // Scroll to the bottom when messages update
//   useEffect(() => {
//     if (scrollbarsRef.current) {
//       scrollbarsRef.current.scrollToBottom();
//     }
//   }, [localMessageData]);

//   // useEffect(() => {
//   //   if (isLoading) {
//   //     const dotInterval = setInterval(() => {
//   //       setDotCount((prev) => (prev === 5 ? 1 : prev + 1)); // Cycle dots from 0 to 3
//   //     }, 500); // Update dots every 500ms
  
//   //     return () => clearInterval(dotInterval); // Cleanup on unmount or when loading finishes
//   //   }
//   // }, [isLoading]);

//   const handleSendMessage = async () => {
//     if (newMessage.trim() !== "") {

//       const updatedUserPrompt = newMessage;
//       setUserPrompt(updatedUserPrompt); // Update context with the new prompt

//       const newMessages = [
//         ...localMessageData,
//         {
//           isUserMessage: true,
//           content: newMessage,
//           time: new Date(),
//         },
//         {
//           isUserMessage: false,
//           // content: "Getting response",
//           content: <AnimatedIcon />,
//           time: new Date(),
//           isLoading: true, // Flag to identify the loading message
//         },
//       ];
  
//       setLocalMessageData(newMessages);
//       setNewMessage("");
//       setIsLoading(true);
  
//       try {
//         const response = await invokeModel(newMessage, systemPrompt, "beat_the_bomb", sessionId, modelPrompt, audiencePrompt, datasetPrompt);
//         const originalResponseJson = JSON.parse(response["original_response_json"]);
    
//         const assistantContentArray = originalResponseJson.content || originalResponseJson.choices || [];
//         let assistantText = "No content available";

//         // Check if content or choices exists and extract the relevant text
//         if (originalResponseJson.content) {
//             assistantText = assistantContentArray.length > 0 ? assistantContentArray[0].text : "No content available";
//         } else if (originalResponseJson.choices) {
//             assistantText = assistantContentArray.length > 0 ? assistantContentArray[0].message.content : "No content available";
//         }

//         const apiResponseMessage = {
//           isUserMessage: false,
//           content: assistantText || "No content available", 
//           time: new Date(),
//         };
  
//         setLocalMessageData((prevMessages) =>
//           prevMessages.map((msg) =>
//             msg.isLoading ? apiResponseMessage : msg
//           )
//         );

//         setDatasetPrompt(null);
        
//       } catch (error) {
//         const errorMessage = {
//           isUserMessage: false,
//           content: `Error: ${error.message}`,
//           time: new Date(),
//         };
  
//         setLocalMessageData((prevMessages) =>
//           prevMessages.map((msg) =>
//             msg.isLoading ? errorMessage : msg
//           )
//         );
//       } finally {
//         setIsLoading(false); 
//       }
//     }
//   };

//   // const renderLoadingDots = () => {
//   //   return '.'.repeat(dotCount); // Repeat dots based on current count
//   // };

//   const formatTime = (time) => {
//     const date = new Date(time);
//     return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
//   };

//   const renderMessageContent = (message) => {
//       // Ensure message is a string
//       const messageString = typeof message === 'string' ? message : JSON.stringify(message, null, 2);
  
//       return messageString.split('\n').map((line, index) => (
//         <span key={index}>
//           {line}
//           <br />
//         </span>
//       ));
//     };

//   const clearMessages = () => {
//     setLocalMessageData([]); // Clear all messages by resetting the state to an empty array
//     setSessionId(generateSessionId()); // Generate a new sessionId after clearing
//   };

//   return (
//     <Box h='100%' {...rest}>
//       <Flex
//         px='34px'
//         pb='25px'
//         borderBottom='1px solid'
//         borderColor={borderColor}
//         align='center'>
//         <LightningChatIconFlex 
//           me='16px'
//           color="inherit" 
//           style={{ marginLeft: 0, fontSize: 30 }}
//         />
//         <Box>
//           <Text
//             color={textColor}
//             fontSize={{ base: "md", md: "xl" }}
//             fontWeight='700'>
//             {name}
//           </Text>
//           <Flex align='center'>
//             <Icon
//               w='6px'
//               h='6px'
//               me='8px'
//               as={FaCircle}
//               color={
//                 status === "active"
//                   ? "green.500"
//                   : status === "away"
//                   ? "orange.500"
//                   : "red.500"
//               }
//             />
//             <Text fontSize={{ base: "sm", md: "md" }}>
//               {status === "active"
//                 ? "Active"
//                 : status === "away"
//                 ? "Away"
//                 : "Offline"}{" "}
//             </Text>
//           </Flex>
//         </Box>
//         <Flex align='center' ms='auto' />
//         <Menu isOpen={isOpen1} onClose={onClose1}>
//           <MenuButton onClick={onOpen1} mb='0px' me='8px'>
//             <Icon
//               mb='-6px'
//               cursor='pointer'
//               as={MdOutlineMoreVert}
//               color={textColor}
//               maxW='min-content'
//               maxH='min-content'
//               w='24px'
//               h='24px'
//             />
//           </MenuButton>
//           <MenuList
//             w='150px'
//             minW='unset'
//             maxW='150px !important'
//             border='transparent'
//             backdropFilter='blur(63px)'
//             bg={bgList}
//             boxShadow={bgShadow}
//             borderRadius='20px'
//             p='15px'>
//             {/* <MenuItem
//               transition='0.2s linear'
//               color={textColor}
//               _hover={textHover}
//               p='0px'
//               borderRadius='8px'
//               _active={{
//                 bg: "transparent",
//               }}
//               _focus={{
//                 bg: "transparent",
//               }}
//               mb='10px'>
//               <Flex align='center'>
//                 <Icon as={MdOutlinePerson} h='16px' w='16px' me='8px' />
//                 <Text fontSize='sm' fontWeight='400'>
//                   Save
//                 </Text>
//               </Flex>
//             </MenuItem> */}
//             <MenuItem
//               transition='0.2s linear'
//               p='0px'
//               borderRadius='8px'
//               color={textColor}
//               _hover={textHover}
//               _active={{
//                 bg: "transparent",
//               }}
//               _focus={{
//                 bg: "transparent",
//               }}
//               mb='0px'
//               onClick={clearMessages}
//               >
//               <Flex align='center'>
//                 <Icon as={MdDeleteForever} h='16px' w='16px' me='8px' />
//                 <Text fontSize='sm' fontWeight='400'>
//                   Delete
//                 </Text>
//               </Flex>
//             </MenuItem>
//           </MenuList>{" "}
//         </Menu>
//         <Icon
//           cursor='pointer'
//           as={FiSearch}
//           color={textColor}
//           w='24px'
//           h='24px'
//         />
//       </Flex>
//       <Box
//         h='calc(100% - 80px)'
//         px={{ base: "10px", md: "20px" }}
//         pt='45px'
//         pb='90px' // Add padding to prevent the last message from being covered
//         position='relative'>
//         <Scrollbars
//           ref={scrollbarsRef} // Attach ref to Scrollbars
//           autoHide
//           renderTrackVertical={messagesRenderTrack}
//           renderThumbVertical={messagesRenderThumb}
//           renderView={messagesRenderView}>
//           <Flex overflow='hidden'>
//   <Flex
//     direction='column'
//     w='100%'
//     maxW={{ base: "90%", lg: "calc(100% - 80px)" }} // Adjust the max width of the entire message container
//     boxSizing='border-box'
//     minHeight="110%"
//   >
//     {localMessageData.map((item, index) => (
//       <Flex
//         key={index}
//         justifyContent={item.isUserMessage ? "flex-end" : "flex-start"} // Align user messages to the right, system messages to the left
//         mb="10px" // Add some margin between messages
//       >
//         <Box
//           maxW="75%" // Set the max width for the bubble to be 2/3 of the message block
//           bg={item.isUserMessage ? userMessageBg : blockBg} // Background based on message type
//           p="25px" // Add padding for spacing inside the bubble
//           borderRadius="15px" // Rounded corners for the bubble
//           wordBreak="break-word" // Ensure long words break properly
//           whiteSpace="pre-wrap" // Preserve newlines and wrap text
//           textAlign="left" // Align text for better readability
//         >
//           {renderMessageContent(item.content)}
//           {/* {item.isLoading && renderLoadingDots()} */}
//           {item.isLoading}
//           <Text
//             mt="5px" // Add a little margin-top for spacing between message and time
//             fontSize="xs" // Smaller font size for the time
//             color={item.isUserMessage ? "gray.700" : "gray.500"}
//           >
//             {formatTime(item.time || new Date())} {/* Format and display time */}
//           </Text>
//         </Box>
//       </Flex>
//     ))}
//   </Flex>
// </Flex>
//         </Scrollbars>
//         <Flex
//           bg={bgInput}
//           backdropFilter='blur(20px)'
//           pt='10px'
//           position='absolute'
//           w={{ base: "calc(100% - 20px)", md: "calc(100% - 40px)" }}
//           bottom='0px'>
//           <InputGroup me='10px' w={{ base: "100%" }}>
//             {/* <InputRightElement
//               display={{ base: "none", lg: "flex" }}
//               children={
//                 <Flex me='70px'>
//                   <IconButton
//                     h='max-content'
//                     w='max-content'
//                     mt='28px'
//                     bg='inherit'
//                     borderRadius='inherit'
//                     _hover='none'
//                     _active={{
//                       bg: "inherit",
//                       transform: "none",
//                       borderColor: "transparent",
//                     }}
//                     _focus={{
//                       boxShadow: "none",
//                     }}
//                     icon={
//                       <Icon
//                         as={MdAttachFile}
//                         color={inputColor}
//                         w='30px'
//                         h='30px'
//                       />
//                     }
//                   />
//                   <IconButton
//                     h='max-content'
//                     w='max-content'
//                     mt='28px'
//                     bg='inherit'
//                     borderRadius='inherit'
//                     _hover='none'
//                     _active={{
//                       bg: "inherit",
//                       transform: "none",
//                       borderColor: "transparent",
//                     }}
//                     _focus={{
//                       boxShadow: "none",
//                     }}
//                     icon={
//                       <Icon
//                         as={MdOutlineImage}
//                         color={inputColor}
//                         w='30px'
//                         h='30px'
//                       />
//                     }
//                   />
//                 </Flex>
//               }
//             /> */}
//             <Input
//               variant='search'
//               fontSize='md'
//               pl={{ base: "40px !important", lg: "65px !important" }}
//               pr={{
//                 base: "0px", 
//                 lg: "145px !important",
//               }}
//               h={{ base: "50px", lg: "70px" }}
//               bg={blockBg}
//               color={inputText}
//               fontWeight='500'
//               _placeholder={{ color: "gray.400", fontSize: "16px" }}
//               borderRadius={"50px"}
//               placeholder={"Write your prompt..."}
//               value={newMessage}
//               onChange={(e) => setNewMessage(e.target.value)}
//               onKeyDown={(e) => {
//                 if (e.key === "Enter") {
//                   handleSendMessage();
//                 }
//               }}
//             />
//           </InputGroup>
//           <Button
//             borderRadius='50%'
//             ms={{ base: "14px", lg: "auto" }}
//             bg={brandButton}
//             w={{ base: "50px", lg: "70px" }}
//             h={{ base: "50px", lg: "70px" }}
//             minW={{ base: "50px", lg: "70px" }}
//             minH={{ base: "50px", lg: "70px" }}
//             variant='no-hover'
//             onClick={handleSendMessage}>
//             <Icon
//               as={IoPaperPlane}
//               color='white'
//               w={{ base: "18px", lg: "25px" }}
//               h={{ base: "18px", lg: "25px" }}
//             />
//           </Button>
//         </Flex>
//       </Box>
//     </Box>
//   );
// }


// Messages.js
import React, { useState, useEffect, useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {
  messagesRenderThumb,
  messagesRenderTrack,
  messagesRenderView,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import { FaCircle } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { IoPaperPlane } from "react-icons/io5";
import {
  MdOutlineMoreVert,
  MdAttachFile,
  MdOutlineImage,
  MdOutlineCardTravel,
} from "react-icons/md";
import { LightningChatIconFlex } from "components/icons/Icons";
import { usePrompt } from 'contexts/PromptContext'; // Import the prompt context
import { invokeModel } from "utils/api";
import CryptoJS from "crypto-js";
import AnimatedIcon from "components/icons/AnimatedIcons";

export default function Messages({
  dataConfirmationMessage, // Added dataset confirmation message
  modelConfirmationMessage, // Added model confirmation message
  messageData = [],
  ...props
}) {
  const { status, name, onSendMessage, ...rest } = props;
  const { datasetPrompt, setDatasetPrompt, modelPrompt, systemPrompt, audiencePrompt, userPrompt, setUserPrompt, sessionId, setSessionId } = usePrompt(); // Add setSessionId here
  const company = sessionStorage.getItem('company') || localStorage.getItem('company');
  const [newMessage, setNewMessage] = useState("");
  const [localMessageData, setLocalMessageData] = useState(messageData); // Local state to manage message data
  const [isLoading, setIsLoading] = useState(false);
  const scrollbarsRef = useRef(null);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const inputText = useColorModeValue("gray.700", "gray.100");
  const blockBg = useColorModeValue("secondaryGray.300", "navy.700");
  const userMessageBg = useColorModeValue("purple.500", "purple.500");
  const brandButton = useColorModeValue("brand.500", "brand.400");
  const bgInput = useColorModeValue(
    "linear-gradient(1.02deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%)",
    "linear-gradient(1.02deg, #111C44 50%, rgba(17, 28, 68, 0) 100%)"
  );

  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const bgList = useColorModeValue("white", "whiteAlpha.100");
  const bgShadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");

  // Function to generate session ID based on current date
  const generateSessionId = () => {
    const currentDate = new Date().toISOString().replace(/[-:.TZ]/g, "");
    const hash = CryptoJS.SHA256(currentDate).toString(CryptoJS.enc.Hex);
    return hash.slice(0, 12); // Return first 12 characters of the hash
  };

  useEffect(() => {
    // Generate sessionId if not already present
    if (!sessionId) {
      const newSessionId = generateSessionId();
      setSessionId(newSessionId); // Update sessionId in context
    }
  }, [sessionId, setSessionId]);

  const addConfirmationMessage = (confirmationMessage, type) => {
    if (confirmationMessage) {
      const newMessage = {
        isUserMessage: false,
        content: `${type} Confirmation:\n${confirmationMessage}`,
        time: new Date(),
      };

      setLocalMessageData((prevMessages) => [...prevMessages, newMessage]);
    }
  };

  // Automatically add the dataset confirmation message when it changes
  useEffect(() => {
    if (dataConfirmationMessage) {
      addConfirmationMessage(dataConfirmationMessage, "Dataset");
    }
  }, [dataConfirmationMessage]);

  // Automatically add the model confirmation message when it changes
  useEffect(() => {
    if (modelConfirmationMessage) {
      addConfirmationMessage(modelConfirmationMessage, "Model");
    }
  }, [modelConfirmationMessage]);

  // Scroll to the bottom when messages update
  useEffect(() => {
    if (scrollbarsRef.current) {
      scrollbarsRef.current.scrollToBottom();
    }
  }, [localMessageData]);


  const handleSendMessage = async () => {
    if (newMessage.trim() !== "") {

      const updatedUserPrompt = newMessage;
      setUserPrompt(updatedUserPrompt); // Update context with the new prompt

      const newMessages = [
        ...localMessageData,
        {
          isUserMessage: true,
          content: newMessage,
          time: new Date(),
        },
        {
          isUserMessage: false,
          content: null,
          time: new Date(),
          isLoading: true, // Flag to identify the loading message
        },
      ];
  
      setLocalMessageData(newMessages);
      setNewMessage("");
      setIsLoading(true);
  
      try {
        const response = await invokeModel(newMessage, systemPrompt, company, sessionId, modelPrompt, audiencePrompt, datasetPrompt);
        const originalResponseJson = JSON.parse(response["original_response_json"]);
    
        const assistantContentArray = originalResponseJson.content || originalResponseJson.choices || [];
        let assistantText = "No content available";

        // Check if content or choices exists and extract the relevant text
        if (originalResponseJson.content) {
            assistantText = assistantContentArray.length > 0 ? assistantContentArray[0].text : "No content available";
        } else if (originalResponseJson.choices) {
            assistantText = assistantContentArray.length > 0 ? assistantContentArray[0].message.content : "No content available";
        }

        const apiResponseMessage = {
          isUserMessage: false,
          content: assistantText || "No content available", 
          time: new Date(),
        };
  
        setLocalMessageData((prevMessages) =>
          prevMessages.map((msg) =>
            msg.isLoading ? apiResponseMessage : msg
          )
        );

        setDatasetPrompt(null);
        
      } catch (error) {
        const errorMessage = {
          isUserMessage: false,
          content: `Error: ${error.message}`,
          time: new Date(),
        };
  
        setLocalMessageData((prevMessages) =>
          prevMessages.map((msg) =>
            msg.isLoading ? errorMessage : msg
          )
        );
      } finally {
        setIsLoading(false); 
      }
    }
  };

  // const renderLoadingDots = () => {
  //   return '.'.repeat(dotCount); // Repeat dots based on current count
  // };

  const formatTime = (time) => {
    const date = new Date(time);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  // const renderMessageContent = (item) => {
  //   if (item.isLoading) {
  //     return <AnimatedIcon />; // Render the animated icon when loading
  //   }
  
  //   const messageString = typeof item.content === 'string' ? item.content : JSON.stringify(item.content, null, 2);
    
  //   return messageString.split('\n').map((line, index) => (
  //     <span key={index}>
  //       {line}
  //       <br />
  //     </span>
  //   ));
  // };

  const renderMessageContent = (item) => {
    if (item.isLoading) {
      return <AnimatedIcon />; // Render the animated icon when loading
    }
  
    // Convert item content to string if it's not already
    const messageString = typeof item.content === 'string' ? item.content : JSON.stringify(item.content, null, 2);
  
    // Remove markdown-style bolding (`**`)
    const cleanedMessage = messageString.replace(/\*\*/g, '');
  
    // Split the message into lines for parsing and rendering
    const lines = cleanedMessage.split('\n').map((line) => line.trim());
  
    return (
      <div>
        {lines.map((line, index) => {
          // Detect sections with "Dataset Confirmation:" or "Model Confirmation:" as headers
          if (/^Dataset Confirmation:|Model Confirmation:/.test(line)) {
            return (
              <h3 key={index} style={{ marginBottom: '8px' }}>
                {line}
              </h3>
            );
          }
  
          // Detect lines that are key-value pairs, like "Dataset: ticketing" or "Location: New York City"
          if (/^[A-Za-z\s]+:\s/.test(line)) {
            return (
              <p key={index} style={{ marginLeft: '16px', marginBottom: '4px' }}>
                {line}
              </p>
            );
          }
  
          // Detect time stamps or single-line times like "08:30 PM"
          if (/^\d{1,2}:\d{2}\s?(AM|PM)$/i.test(line)) {
            return (
              <p key={index} style={{ color: 'gray', fontSize: '0.9em', marginBottom: '4px' }}>
                {line}
              </p>
            );
          }
  
          // Check for numbered lists and display normally without bolding
          if (/^\d+\.\s/.test(line)) {
            return (
              <p key={index} style={{ marginLeft: '16px', marginBottom: '8px' }}>
                {line}
              </p>
            );
          }
  
          // General paragraph for all other content
          return (
            <p key={index} style={{ marginBottom: '8px' }}>
              {line}
            </p>
          );
        })}
      </div>
    );
  };
  
  const clearMessages = () => {
    setLocalMessageData([]); // Clear all messages by resetting the state to an empty array
    setSessionId(generateSessionId()); // Generate a new sessionId after clearing
  };

  return (
    <Box h='100%' {...rest}>
      <Flex
        px='34px'
        pb='25px'
        borderBottom='1px solid'
        borderColor={borderColor}
        align='center'>
        <LightningChatIconFlex 
          me='16px'
          color="inherit" 
          style={{ marginLeft: 0, fontSize: 30 }}
        />
        <Box>
          <Text
            color={textColor}
            fontSize={{ base: "md", md: "xl" }}
            fontWeight='700'>
            {name}
          </Text>
          <Flex align='center'>
            <Icon
              w='6px'
              h='6px'
              me='8px'
              as={FaCircle}
              color={
                status === "active"
                  ? "green.500"
                  : status === "away"
                  ? "orange.500"
                  : "red.500"
              }
            />
            <Text fontSize={{ base: "sm", md: "md" }}>
              {status === "active"
                ? "Active"
                : status === "away"
                ? "Away"
                : "Offline"}{" "}
            </Text>
          </Flex>
        </Box>
        <Flex align='center' ms='auto' />
        <Menu isOpen={isOpen1} onClose={onClose1}>
          <MenuButton onClick={onOpen1} mb='0px' me='8px'>
            <Icon
              mb='-6px'
              cursor='pointer'
              as={MdOutlineMoreVert}
              color={textColor}
              maxW='min-content'
              maxH='min-content'
              w='24px'
              h='24px'
            />
          </MenuButton>
          <MenuList
            w='150px'
            minW='unset'
            maxW='150px !important'
            border='transparent'
            backdropFilter='blur(63px)'
            bg={bgList}
            boxShadow={bgShadow}
            borderRadius='20px'
            p='15px'>
            <MenuItem
              transition='0.2s linear'
              p='0px'
              borderRadius='8px'
              color={textColor}
              _hover={textHover}
              _active={{
                bg: "transparent",
              }}
              _focus={{
                bg: "transparent",
              }}
              mb='0px'
              onClick={clearMessages}
              >
              <Flex align='center'>
                <Icon as={MdDeleteForever} h='16px' w='16px' me='8px' />
                <Text fontSize='sm' fontWeight='400'>
                  Delete
                </Text>
              </Flex>
            </MenuItem>
          </MenuList>{" "}
        </Menu>
        <Icon
          cursor='pointer'
          as={FiSearch}
          color={textColor}
          w='24px'
          h='24px'
        />
      </Flex>
      <Box
        h='calc(100% - 80px)'
        px={{ base: "10px", md: "20px" }}
        pt='45px'
        pb='90px' // Add padding to prevent the last message from being covered
        position='relative'>
        <Scrollbars
          ref={scrollbarsRef} // Attach ref to Scrollbars
          autoHide
          renderTrackVertical={messagesRenderTrack}
          renderThumbVertical={messagesRenderThumb}
          renderView={messagesRenderView}>
          <Flex overflow='hidden'>
  <Flex
    direction='column'
    w='100%'
    maxW={{ base: "90%", lg: "calc(100% - 80px)" }} // Adjust the max width of the entire message container
    boxSizing='border-box'
    minHeight="110%"
  >
    {localMessageData.map((item, index) => (
      <Flex
        key={index}
        justifyContent={item.isUserMessage ? "flex-end" : "flex-start"} // Align user messages to the right, system messages to the left
        mb="10px"
      >
        <Box
          maxW="75%" 
          bg={item.isUserMessage ? userMessageBg : blockBg}
          p="25px"
          borderRadius="15px"
          wordBreak="break-word"
          whiteSpace="pre-wrap"
          textAlign="left"
        >
          {renderMessageContent(item)}
          <Text
            mt="5px"
            fontSize="xs"
            color={item.isUserMessage ? "gray.700" : "gray.500"}
          >
            {formatTime(item.time || new Date())}
          </Text>
        </Box>
      </Flex>
    ))}
  </Flex>
</Flex>
        </Scrollbars>
        <Flex
          bg={bgInput}
          backdropFilter='blur(20px)'
          pt='10px'
          position='absolute'
          w={{ base: "calc(100% - 20px)", md: "calc(100% - 40px)" }}
          bottom='0px'>
          <InputGroup me='10px' w={{ base: "100%" }}>
            <Input
              variant='search'
              fontSize='md'
              pl={{ base: "40px !important", lg: "65px !important" }}
              pr={{
                base: "0px", 
                lg: "145px !important",
              }}
              h={{ base: "50px", lg: "70px" }}
              bg={blockBg}
              color={inputText}
              fontWeight='500'
              _placeholder={{ color: "gray.400", fontSize: "16px" }}
              borderRadius={"50px"}
              placeholder={"Write your prompt..."}
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
            />
          </InputGroup>
          <Button
            borderRadius='50%'
            ms={{ base: "14px", lg: "auto" }}
            bg={brandButton}
            w={{ base: "50px", lg: "70px" }}
            h={{ base: "50px", lg: "70px" }}
            minW={{ base: "50px", lg: "70px" }}
            minH={{ base: "50px", lg: "70px" }}
            variant='no-hover'
            onClick={handleSendMessage}>
            <Icon
              as={IoPaperPlane}
              color='white'
              w={{ base: "18px", lg: "25px" }}
              h={{ base: "18px", lg: "25px" }}
            />
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}
