const summaryCards = {
  "illuminarium": {
    ticketing: [
      {
        tabKey: 'ticketing',
        clientFilter: 'illuminarium',
        locationFilter: 'Chicago',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      {
        tabKey: 'ticketing',
        clientFilter: 'illuminarium',
        locationFilter: 'Las Vegas',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-09-30'
      },
      {
        tabKey: 'ticketing',
        clientFilter: 'illuminarium',
        locationFilter: 'Toronto',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      {
        tabKey: 'ticketing',
        clientFilter: 'illuminarium',
        locationFilter: 'Chicago',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)',
        dateAdded: '2024-10-06'
      },
      {
        tabKey: 'ticketing',
        clientFilter: 'illuminarium',
        locationFilter: 'Las Vegas',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)',
        dateAdded: '2024-10-06'
      },
      {
        tabKey: 'ticketing',
        clientFilter: 'illuminarium',
        locationFilter: 'Toronto',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)',
        dateAdded: '2024-10-06'
      },
    ],
    meta: [
      {
        tabKey: 'meta',
        clientFilter: 'illuminarium',
        locationFilter: 'Chicago',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      {
        tabKey: 'meta',
        clientFilter: 'illuminarium',
        locationFilter: 'Las Vegas',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-09-30'
      },
      {
        tabKey: 'meta',
        clientFilter: 'illuminarium',
        locationFilter: 'Toronto',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      {
        tabKey: 'meta',
        clientFilter: 'illuminarium',
        locationFilter: 'Chicago',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)',
        dateAdded: '2024-10-06'
      },
      {
        tabKey: 'meta',
        clientFilter: 'illuminarium',
        locationFilter: 'Las Vegas',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)',
        dateAdded: '2024-10-06'
      },
      {
        tabKey: 'meta',
        clientFilter: 'illuminarium',
        locationFilter: 'Toronto',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)',
        dateAdded: '2024-10-06'
      },
    ],    
    google: [
      {
        tabKey: 'google',
        clientFilter: 'illuminarium',
        locationFilter: 'Chicago',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      {
        tabKey: 'google',
        clientFilter: 'illuminarium',
        locationFilter: 'Las Vegas',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-09-30'
      },
      {
        tabKey: 'google',
        clientFilter: 'illuminarium',
        locationFilter: 'Toronto',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      {
        tabKey: 'google',
        clientFilter: 'illuminarium',
        locationFilter: 'Chicago',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)',
        dateAdded: '2024-10-06'
      },
      {
        tabKey: 'google',
        clientFilter: 'illuminarium',
        locationFilter: 'Las Vegas',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)',
        dateAdded: '2024-10-06'
      },
      {
        tabKey: 'google',
        clientFilter: 'illuminarium',
        locationFilter: 'Toronto',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)',
        dateAdded: '2024-10-06'
      },
    ], 
  },
  "beat_the_bomb": {
    ticketing: [
      {
        tabKey: 'ticketing',
        clientFilter: 'beat_the_bomb',
        locationFilter: 'Atlanta',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      {
        tabKey: 'ticketing',
        clientFilter: 'beat_the_bomb',
        locationFilter: 'Brooklyn',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-09-30'
      },
      {
        tabKey: 'ticketing',
        clientFilter: 'beat_the_bomb',
        locationFilter: 'Washington',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      {
        tabKey: 'ticketing',
        clientFilter: 'beat_the_bomb',
        locationFilter: 'Atlanta',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)',
        dateAdded: '2024-10-06'
      },
      {
        tabKey: 'ticketing',
        clientFilter: 'beat_the_bomb',
        locationFilter: 'Brooklyn',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)',
        dateAdded: '2024-10-06'
      },
      {
        tabKey: 'ticketing',
        clientFilter: 'beat_the_bomb',
        locationFilter: 'Washington',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)',
        dateAdded: '2024-10-06'
      }
    ],
    meta: [
      {
        tabKey: 'meta',
        clientFilter: 'beat_the_bomb',
        locationFilter: 'Atlanta',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      {
        tabKey: 'meta',
        clientFilter: 'beat_the_bomb',
        locationFilter: 'Brooklyn',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-09-30'
      },
      {
        tabKey: 'meta',
        clientFilter: 'beat_the_bomb',
        locationFilter: 'Washington',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      {
        tabKey: 'meta',
        clientFilter: 'beat_the_bomb',
        locationFilter: 'Atlanta',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)',
        dateAdded: '2024-10-06'
      },
      {
        tabKey: 'meta',
        clientFilter: 'beat_the_bomb',
        locationFilter: 'Brooklyn',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)',
        dateAdded: '2024-10-06'
      },
      {
        tabKey: 'meta',
        clientFilter: 'beat_the_bomb',
        locationFilter: 'Washington',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)',
        dateAdded: '2024-10-06'
      },
    ],    
    google: [
      {
        tabKey: 'google',
        clientFilter: 'beat_the_bomb',
        locationFilter: 'Atlanta',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      {
        tabKey: 'google',
        clientFilter: 'beat_the_bomb',
        locationFilter: 'Brooklyn',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-09-30'
      },
      {
        tabKey: 'google',
        clientFilter: 'beat_the_bomb',
        locationFilter: 'Washington',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      {
        tabKey: 'google',
        clientFilter: 'beat_the_bomb',
        locationFilter: 'Atlanta',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)',
        dateAdded: '2024-10-06'
      },
      {
        tabKey: 'google',
        clientFilter: 'beat_the_bomb',
        locationFilter: 'Brooklyn',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)',
        dateAdded: '2024-10-06'
      },
      {
        tabKey: 'google',
        clientFilter: 'beat_the_bomb',
        locationFilter: 'Washington',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)',
        dateAdded: '2024-10-06'
      },
    ],
  },
  "sloomoo_institute": {
    ticketing: [
      { 
        tabKey: 'ticketing',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Atlanta',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      { 
        tabKey: 'ticketing',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Chicago',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-09-30'
      },
      { 
        tabKey: 'ticketing',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Houston',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      { 
        tabKey: 'ticketing',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'New York City',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-09-30'
      },
      { 
        tabKey: 'ticketing',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Los Angeles',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      { 
        tabKey: 'ticketing',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Atlanta',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-10-06'
      },
      { 
        tabKey: 'ticketing',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Chicago',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-10-06'
      },
      { 
        tabKey: 'ticketing',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Houston',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-10-06'
      },
      { 
        tabKey: 'ticketing',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'New York City',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-10-06'
      },
      { 
        tabKey: 'ticketing',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Los Angeles',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-10-06'
      }
    ],
    meta: [
      { 
        tabKey: 'meta',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Atlanta',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      { 
        tabKey: 'meta',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Chicago',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      { 
        tabKey: 'meta',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Houston',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-09-30'
      },
      { 
        tabKey: 'meta',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'New York City',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      { 
        tabKey: 'meta',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Los Angeles',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-09-30'
      },
      { 
        tabKey: 'meta',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Atlanta',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-10-06'
      },
      { 
        tabKey: 'meta',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Chicago',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-10-06'
      },
      { 
        tabKey: 'meta',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Houston',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-10-06'
      },
      { 
        tabKey: 'meta',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'New York City',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-10-06'
      },
      { 
        tabKey: 'meta',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Los Angeles',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-10-06'
      },
    ],    
    google: [
      { 
        tabKey: 'google',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Atlanta',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      { 
        tabKey: 'google',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Chicago',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-09-30'
      },
      { 
        tabKey: 'google',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Houston',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      { 
        tabKey: 'google',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'New York City',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-09-30'
      },
      { 
        tabKey: 'google',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Los Angeles',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      { 
        tabKey: 'google',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Atlanta',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-10-06'
      },
      { 
        tabKey: 'google',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Chicago',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-10-06'
      },
      { 
        tabKey: 'google',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Houston',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-10-06'
      },
      { 
        tabKey: 'google',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'New York City',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-10-06'
      },
      { 
        tabKey: 'google',
        clientFilter: 'sloomoo_institute',
        locationFilter: 'Los Angeles',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-10-06'
      },
    ],    
  },
  "arcadia_earth": {
    ticketing: [
      { 
        tabKey: 'ticketing',
        clientFilter: 'arcadia_earth',
        locationFilter: 'Toronto',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      { 
        tabKey: 'ticketing',
        clientFilter: 'arcadia_earth',
        locationFilter: 'Toronto',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-10-06'
      }
    ],
    meta: [
      { 
        tabKey: 'meta',
        clientFilter: 'arcadia_earth',
        locationFilter: 'Toronto',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-09-30'
      },
      { 
        tabKey: 'meta',
        clientFilter: 'arcadia_earth',
        locationFilter: 'Toronto',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-10-06'
      },
    ],    
    google: [
      { 
        tabKey: 'google',
        clientFilter: 'arcadia_earth',
        locationFilter: 'Toronto',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
        currentDateRange: 'September 23, 2024 - September 29, 2024',
        pastDateRange: 'September 16, 2024 - September 22, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-09-30'
      },
      { 
        tabKey: 'google',
        clientFilter: 'arcadia_earth',
        locationFilter: 'Toronto',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-10-06'
      }
    ],
  },
  "a_bunch_of_stuff": {
    ticketing: [
      // { 
      //   tabKey: 'ticketing',
      //   clientFilter: 'a_bunch_of_stuff',
      //   locationFilter: 'New York City',
      //   tableNameFilter: 'ticketing_day_insights',
      //   currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
      //   pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
      //   currentDateRange: 'September 23, 2024 - September 29, 2024',
      //   pastDateRange: 'September 16, 2024 - September 22, 2024',
      //   color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
      //   dateAdded: '2024-09-30'
      // },
      { 
        tabKey: 'ticketing',
        clientFilter: 'a_bunch_of_stuff',
        locationFilter: 'New York City',
        tableNameFilter: 'ticketing_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-10-06'
      },
    ],
    meta: [
      // { 
      //   tabKey: 'meta',
      //   clientFilter: 'a_bunch_of_stuff',
      //   locationFilter: 'New York City',
      //   tableNameFilter: 'meta_day_insights',
      //   currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
      //   pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
      //   currentDateRange: 'September 23, 2024 - September 29, 2024',
      //   pastDateRange: 'September 16, 2024 - September 22, 2024',
      //   color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
      //   dateAdded: '2024-09-30'
      // },
      { 
        tabKey: 'meta',
        clientFilter: 'a_bunch_of_stuff',
        locationFilter: 'New York City',
        tableNameFilter: 'meta_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#4B21F3 15%, #A8BBFF 70%, #FFFFFF)', 
        dateAdded: '2024-10-06'
      },
    ],    
    google: [
      // { 
      //   tabKey: 'google',
      //   clientFilter: 'a_bunch_of_stuff',
      //   locationFilter: 'New York City',
      //   tableNameFilter: 'google_day_insights',
      //   currentDateRangeFilter: '["2024-09-23", "2024-09-29"]',
      //   pastDateRangeFilter: '["2024-09-16", "2024-09-22"]',
      //   currentDateRange: 'September 23, 2024 - September 29, 2024',
      //   pastDateRange: 'September 16, 2024 - September 22, 2024',
      //   color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
      //   dateAdded: '2024-09-30'
      // },
      { 
        tabKey: 'google',
        clientFilter: 'a_bunch_of_stuff',
        locationFilter: 'New York City',
        tableNameFilter: 'google_day_insights',
        currentDateRangeFilter: '["2024-09-30", "2024-10-06"]',
        pastDateRangeFilter: '["2024-09-23", "2024-09-29"]',
        currentDateRange: 'September 30, 2024 - October 6, 2024',
        pastDateRange: 'September 23, 2024 - September 29, 2024',
        color: 'linear(#008080 15%, #2AE7DD 70%, #CEFFFC)', 
        dateAdded: '2024-10-06'
      },
    ],    
    
  },
};

module.exports = { summaryCards };