// api.js
export async function invokeModel(userPrompt, systemPrompt, clientId) {
  // Clone the prompts to avoid mutating the original data
  const clonedSystemPrompt = JSON.parse(JSON.stringify(systemPrompt));
  const clonedUserPrompt = JSON.parse(JSON.stringify(userPrompt));

  // Proceed with the API request
  try {
    const response = await fetch("https://xeuscloud-app-service.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/generate_response", {
    // const response = await fetch("http://localhost:8000/generate_response", {

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        system_prompts: clonedSystemPrompt,
        messages: clonedUserPrompt,
        model_id: "anthropic.claude-3-sonnet-20240229-v1:0"
      }),
    });

    // Check for HTTP errors
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Parse the response
    const data = await response.json();

    // Upload the response to the /upload_response endpoint
    const uploadResponse = await fetch("https://xeuscloud-app-service.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/upload_response", {
    // const uploadResponse = await fetch("http://localhost:8000/upload_response", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        output: JSON.stringify(data),  // Send the response as a string
        client_id: clientId,  // Pass the client_id as is
      }),
    });

    // Check for errors in the upload
    if (!uploadResponse.ok) {
      throw new Error(`Upload error! status: ${uploadResponse.status}`);
    }

    console.log("Response uploaded successfully!");

    return data;

  } catch (error) {
    console.error("API request error:", error.message);
    throw error; // Rethrow the error after logging it
  }
}
