import React, { useEffect } from "react";
import { Box, Icon } from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { HiOutlineSparkles } from "react-icons/hi2";

// Wrapping Chakra's Box or Icon component with Framer Motion
const MotionBox = motion(Box);

const AnimatedIcon = () => {
    const controls = useAnimation();

    useEffect(() => {
        const animateIcon = async () => {
        while (true) {
            await controls.start({ scale: 0.8, transition: { duration: 0.5 } }); 
            await controls.start({ rotate: -15, transition: { duration: 0.1 } }); 
            await controls.start({ rotate: 15, transition: { duration: 0.1 } });
            await controls.start({ rotate: 0, transition: { duration: 0.1 } });
            await controls.start({ scale: 1.2, transition: { duration: 0.5 } });
        }
        };
        animateIcon();
    }, [controls]);

    return (
        <MotionBox
        display="inline-block"
        animate={controls}
        _hover={{ cursor: "pointer" }}
        transition={{
            duration: 3,
            repeat: Infinity, 
            repeatType: "loop",
            ease: "easeInOut",
            }}
        >
        <Icon as={HiOutlineSparkles} w={8} h={8} color="yellow.500" />
        </MotionBox>
    );
};

export default AnimatedIcon;