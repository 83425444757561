// // ChatPage.jsx
// import {
//   Box,
//   Button,
//   Drawer,
//   DrawerBody,
//   DrawerContent,
//   Grid,
//   Icon,
//   DrawerCloseButton,
//   useColorModeValue,
//   useDisclosure,
// } from "@chakra-ui/react";
// import { MdChevronLeft } from "react-icons/md";
// // Custom components
// import Card from "components/card/Card.js";
// import React, { useState } from "react";
// import Messages from "./components/Messages";
// import DataSidebar from "./components/DataSidebar";

// export default function ChatPage() {
//   // Chakra Color Mode
//   const searchIconColor = useColorModeValue("gray.700", "white");
//   let sidebarBackgroundColor = useColorModeValue("white", "navy.800");
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const [messages, setMessages] = useState([]);
//   const [confirmationMessage, setConfirmationMessage] = useState('');

//   const handleConfirmSelection = (dataset, location, dateRange, system, user) => {

//     const formatDate = (date) => {
//       const options = { year: 'numeric', month: 'long', day: 'numeric' };
//       return date.toLocaleDateString(undefined, options);
//     };
  
//     const formattedDateRange = `${formatDate(dateRange[0])} - ${formatDate(dateRange[1])}`;

//     const message = `You have selected:
//       Dataset: ${dataset || 'None'}
//       Location: ${location || 'None'}
//       Date Range: ${dateRange ? formattedDateRange : 'None'}
//       System: ${system || 'None'}
//       User: ${user || 'None'}`;

//     setConfirmationMessage(message); // Set the confirmation message
//   };

//   // // Function to handle new messages
//   // const handleSendMessage = (newMessage) => {
//   //   setMessages((prevMessages) => [
//   //     ...prevMessages,
//   //     {
//   //       content: newMessage,
//   //       time: new Date().toISOString(), // Store the date as a valid ISO string
//   //       isUserMessage: true, // Mark this as a user message
//   //     },
//   //   ]);
//   // };

//   // // Function to handle the display of filtered data
//   // const handleDisplayData = (newFilteredData) => {
//   //   setMessages((prevMessages) => [
//   //     ...prevMessages,
//   //     ...newFilteredData.map(data => ({
//   //       content: data,
//   //       time: new Date().toISOString(), // Add the current time
//   //       isUserMessage: false, // Mark this as a system message (not a user message)
//   //     }))
//   //   ]);
//   // };

//   return (
//     <Grid
//   display={{ base: "block", md: "grid" }}
//   pt={{ base: "130px", md: "80px", xl: "80px" }}
//   gridTemplateColumns={{ base: "1fr 3fr", md: "1fr 3fr" }} // Adjust proportions
//   gap={{ base: "20px", xl: "20px" }}
//   h="calc(100vh - 80px)" // Ensure the grid occupies the full available height
// >
//   {/* DataSidebar Card */}
//   <Card gridArea='1 / 1 / 2 / 2' p='0px' h="100%" w="100%" display="flex" flexDirection="column">
//   <DataSidebar 
//     onConfirmSelection={handleConfirmSelection}
//     style={{ flex: 1, height: '100%', width: '100%' }} // Ensure full width and height
//   />
// </Card>


//   {/* Messages Card */}
//   <Card
//     display={{ base: "none", md: "flex" }}
//     h='100%'
//     gridArea='1 / 2 / 2 / 3'
//     px='0px'
//     flexDirection="column"
//   >
//     <Messages
//       status='active'
//       name='Spark'
//       messageData={messages}
//       confirmationMessage={confirmationMessage}
//       style={{ flex: 1 }} // Flex grow to fill entire card space
//     />
//   </Card>

//   {/* Drawer for Mobile View */}
//   <Drawer isOpen={isOpen} placement='left' onClose={onClose}>
//     <DrawerContent
//       w='100%'
//       maxW='calc(100vw - 32px)'
//       ms={{ sm: "16px" }}
//       my={{ sm: "16px" }}
//       borderRadius='16px'
//       bg={sidebarBackgroundColor}
//     >
//       <DrawerCloseButton
//         boxShadow='unset !important'
//         _hover={{ bg: "transparent" }}
//         left='0px !important'
//         top='18px'
//         color='secondaryGray.700'
//       >
//         <Icon
//           as={MdChevronLeft}
//           color={searchIconColor}
//           w='28px'
//           h='28px'
//         />
//       </DrawerCloseButton>
//       <DrawerBody px='0px'>
//         <Messages
//           status='active'
//           name='Chat Bot'
//           messageData={messages}
//           confirmationMessage={confirmationMessage}
//         />
//       </DrawerBody>
//     </DrawerContent>
//   </Drawer>
// </Grid>

//   );
// }


// spark.jsx
import {
  Grid,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React, { useState } from "react";
import Messages from "./components/Messages";
import DataSidebar from "./components/DataSidebar";

export default function ChatPage() {
  // Chakra Color Mode
  const initialMessage = {
    "content": "Hi! Welcome to Spark Chat. This is a space to discover information and spark new ideas. To begin, select the data, and time period you would like to chat about today",
    "loading": false
  };
  const [messages, setMessages] = useState([initialMessage]);
  const [dataConfirmationMessage, setDataConfirmationMessage] = useState('');
  const [modelConfirmationMessage, setModelConfirmationMessage] = useState('');

  const handleDataConfirmSelection = (dataset, location, dateRange) => {

    const formatDate = (date) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    };
  
    const formattedDateRange = `${formatDate(dateRange[0])} - ${formatDate(dateRange[1])}`;

    const message = `Dataset: ${dataset || 'None'}
      Location: ${location || 'None'}
      Date Range: ${dateRange ? formattedDateRange : 'None'}`;

    setDataConfirmationMessage(message); // Set the confirmation message
  };

  const handleModelConfirmSelection = (model, system, audience) => {

    // const message = `You have selected:
    //   Model: ${model || 'None'}
    //   System: ${system || 'None'}
    //   Audience: ${audience || 'None'}`;

      const message = `Model: ${model || 'None'}`;

    setModelConfirmationMessage(message); // Set the confirmation message
  };

  return (
    <Grid
      display={{ base: "block", md: "grid" }}
      gridTemplateColumns={{ base: "1fr 3fr", md: "1fr 3fr" }}
      gap={{ base: "20px", xl: "20px" }}
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      h="calc(100vh - 80px)"
    >
      {/* DataSidebar Card */}
        <DataSidebar 
          onDataConfirmSelection={handleDataConfirmSelection}
          onModelConfirmSelection={handleModelConfirmSelection} // Pass the model confirmation handler
          style={{ flex: 1, height: '100%', width: '100%' }} // Ensure full width and height
        />

      {/* Messages Card */}
      <Card
        display={{ base: "none", md: "flex" }}
        h='100%'
        gridArea='1 / 2 / 2 / 3'
        px='0px'
        flexDirection="column"
      >
        <Messages
          status='active'
          name='Spark AI Chat'
          messageData={messages}
          dataConfirmationMessage={dataConfirmationMessage} 
          modelConfirmationMessage={modelConfirmationMessage}
          style={{ flex: 1 }} // Flex grow to fill entire card space
        />
      </Card>

    </Grid>
  );
}
