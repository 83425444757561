import {
  VStack,
  Button,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { capitalizeWords } from 'utils/dataFormatter';

const SelectDataset = ({ viewPathMap, selectedDataset, handleDatasetSelection, }) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <>
      <Text fontSize="l" mb={4}>Select Dataset</Text>
      <VStack align="start" mb={4}>
        {Object.keys(viewPathMap).map((dataset) => (
          <Button
            key={dataset}
            backgroundColor={selectedDataset === dataset ? '#5158FF' : 'white.300'}
            color={selectedDataset === dataset ? 'white' : textColor}
            _hover={{ backgroundColor: selectedDataset === dataset ? '#3d44cc' : 'rgba(255, 255, 255, 0.1)' }}
            onClick={() => handleDatasetSelection(dataset)}
            w="full"
            fontSize="sm"
          >
            {capitalizeWords(dataset.replace(/_/g, ' '))}
          </Button>
        ))}
      </VStack>
    </>
  );
};

export default SelectDataset;
