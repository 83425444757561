// // LocationContext.js
// // React Imports
// import React, { createContext, useState, useContext } from 'react';

// const LocationContext = createContext();

// export const LocationProvider = ({ children }) => {
//   const [location, setLocation] = useState("Las Vegas");

//   return (
//     <LocationContext.Provider value={{ location, setLocation }}>
//       {children}
//     </LocationContext.Provider>
//   );
// };

// export const useLocation = () => useContext(LocationContext);


// LocationContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth } from 'AuthContext';
// import { locationsList } from '../variables/locationsList';
import locationsList from '../variables/locationsList';

const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const { company } = useAuth();

  // Get the locations for the current company, defaulting to an empty array if the company is not found
  const companyLocations = locationsList[company] || [];

  // Initially set location to an empty string
  const [location, setLocation] = useState("");

  // Update the location once companyLocations is available
  useEffect(() => {
    if (companyLocations.length > 0) {
      setLocation(companyLocations[0]);
    }
  }, [companyLocations]);

  return (
    <LocationContext.Provider value={{ location, setLocation, companyLocations }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocation = () => useContext(LocationContext);