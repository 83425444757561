// map.jsx
// Chakra / React Imports
import { Spinner, Flex, useToast, Button } from "@chakra-ui/react";
import { useDateRange } from "contexts/DateRangeContext";
import { useLocation } from "contexts/LocationContext";
import React, { useEffect, useState } from "react";
import { useFilteredData } from "utils/dataHooks";
import { getGeolocationByLocation, getCoordinatesFromZipcode } from "utils/MapsUtils";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
// Fix for missing marker icons in Leaflet
import L from "leaflet";
import markerIconBlue1 from "../../../assets/img/marker/blue-1.png";
import markerIconBlue3 from "../../../assets/img/marker/blue-3.png";
import markerIconBlue5 from "../../../assets/img/marker/blue-5.png";
import markerIconYellow1 from "../../../assets/img/marker/yellow-1.png";
import markerIconYellow3 from "../../../assets/img/marker/yellow-3.png";
import markerIconYellow5 from "../../../assets/img/marker/yellow-5.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import { formatNumber } from "utils/dataFormatter";
import { FaSync } from "react-icons/fa";

let DefaultIcon = L.icon({
  iconUrl: markerIconBlue1,
  shadowUrl: markerShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

function Default() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData } = useFilteredData("tickets_location_insights", location, dateRange);
  const [currentLocationCoordinates, setCurrentLocationCoordinates] = useState([36.1699, -115.1398]);
  const [dataWithCoordinates, setDataWithCoordinates] = useState([]);
  const [isAbleToReload, setIsAbleToReload] = useState(false);
  const toast = useToast();
  
  useEffect(() => {
    const getCurrentLocationCoordinates = async () => {
      try {
        const { lat, lng } = await getGeolocationByLocation(location);
        setCurrentLocationCoordinates([lat, lng]);
        setIsAbleToReload(false);
      } catch (error) {
        console.error(error);
        setIsAbleToReload(true);
        toast({
          title: 'Something went wrong',
          description: 'Please reload the page',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top'
        });
      }
    };

    if (location) {
      getCurrentLocationCoordinates();
    }
  }, [location, toast]);

  useEffect(() => {
    const mapData = async () => {
      const filteredData = currentPeriodData.filter((data) => data.zipcode !== null);
      const mappedData = await Promise.all(
        filteredData.map(async (data) => {
          try {
            const coordinates = await getCoordinatesFromZipcode(data.zipcode, data.city, data.state, data.country);
            return {
              ...data,
              lat: coordinates?.lat || null,
              lng: coordinates?.lng || null
            };
          } catch (error) {
            console.error(`Error getting coordinates for zipcode ${data.zipcode}:`, error);
            return {
              ...data,
              lat: null,
              lng: null
            };
          }
        })
      );
      
      const validMappedData = mappedData.filter(data => data.lat !== null && data.lng !== null);
      
      const groupedData = validMappedData.reduce((acc, item) => {
        const existingEntry = acc.find(entry => entry.zipcode === item.zipcode);
        
        if (existingEntry) {
          existingEntry.tk_total_orders += item.tk_total_orders;
          existingEntry.tk_total_tickets += item.tk_total_tickets;
          existingEntry.tk_ticket_gross_revenues += item.tk_ticket_gross_revenues;
          existingEntry.tk_ticket_net_revenues += item.tk_ticket_net_revenues;
        } else {
          acc.push({
            zipcode: item.zipcode,
            city: item.city,
            state: item.state,
            lat: item.lat,
            lng: item.lng,
            tk_total_orders: item.tk_total_orders,
            tk_total_tickets: item.tk_total_tickets,
            tk_ticket_gross_revenues: item.tk_ticket_gross_revenues,
            tk_ticket_net_revenues: item.tk_ticket_net_revenues,
          });
        }

        return acc;
      }, []);
    
      setDataWithCoordinates(groupedData);
    };

    if (currentPeriodData) {
      mapData();
    }
  }, [currentPeriodData]);

  const chooseIcon = (netRevenue, maxNetRevenue) => {
    const threshold1 = maxNetRevenue * 0.1;
    const threshold3 = maxNetRevenue * 0.3;
    const threshold5 = maxNetRevenue * 0.5;
    const threshold6 = maxNetRevenue * 0.6;
    const threshold8 = maxNetRevenue * 0.8;
    const threshold10 = maxNetRevenue * 1;

    if (netRevenue < threshold1) return markerIconBlue1;
    if (netRevenue < threshold3) return markerIconBlue3;
    if (netRevenue < threshold5) return markerIconBlue5;
    if (netRevenue < threshold6) return markerIconYellow1;
    if (netRevenue < threshold8) return markerIconYellow3;
    if (netRevenue < threshold10) return markerIconYellow5;
    return markerIconYellow5;
  };

  const MapWithMarkers = ({ locations }) => {
    const maxNetRevenue = Math.max(...locations.map(location => location.tk_ticket_net_revenues));

    return (
      <MapContainer
        center={currentLocationCoordinates}
        zoom={10}
        style={{ height: "80vh", width: "100%", zIndex: 0 }}
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        />
        {locations?.map((location, index) => {
          const icon = L.icon({
            iconUrl: chooseIcon(location.tk_ticket_net_revenues, maxNetRevenue),
            shadowUrl: markerShadow,
          });

          return (
            <Marker key={index} position={[location.lat, location.lng]} icon={icon}>
              <Popup>
                <div className="popup">
                  {location.city && (
                    <strong>
                      {location.city}, {location.state}
                    </strong>
                  )}
                  <br />
                  <strong>({location.zipcode})</strong>
                  <hr />
                  <p>Total Orders: <strong>{location.tk_total_orders}</strong></p>
                  <p>Total Tickets: <strong>{location.tk_total_tickets}</strong></p>
                  <p>Gross Revenues: <strong>{formatNumber(location.tk_ticket_gross_revenues, 'dollar')}</strong></p>
                  <p>Net Revenues: <strong>{formatNumber(location.tk_ticket_net_revenues, 'dollar')}</strong></p>
                </div>
              </Popup>
            </Marker>
          );
        })}
      </MapContainer>
    );
  };

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      height="100vh"
    >
      {dataWithCoordinates.length > 0 ? (
        <MapWithMarkers locations={dataWithCoordinates} />
      ) : isAbleToReload ? (
        <Button leftIcon={<FaSync />} onClick={() => window.location.reload()}>
          Reload
        </Button>
      ) : (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="#00E5D1"
          size="xl"
        />
      )}
    </Flex>
  );
}

export default Default;
