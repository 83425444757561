  // // DataSidebar.js
  // import React, { useState, useEffect } from 'react';
  // import { Box, VStack, Button, Text, Flex, Divider, useColorModeValue } from '@chakra-ui/react';
  // import { Scrollbars } from 'react-custom-scrollbars-2';
  // import DateFilter from 'components/filters/DateFilter';
  // import LocationFilter from 'components/filters/LocationFilter';
  // import { useLocation } from 'contexts/LocationContext';
  // import { useDateRange } from 'contexts/DateRangeContext';
  // import { useFilteredData } from 'utils/dataHooks'; 
  // import { usePrompt } from 'contexts/PromptContext'; // Import the prompt context
  // import { format } from 'date-fns';

  // const viewPathMap = {
  //   'meta': 'meta',
  //   'google': 'google',
  //   'ticketing': 'ticketing',
  // };

  // // const systems = ['Data Scientist', 'Marketing Analyst', 'Economist', 'Software Engineer', 'None'];
  // // const users = ['Stakeholder', 'Teenager', 'Grandmother', 'Senator', 'Musician', 'None'];
  // const models = ["Anthropic", "OpenAI"]

  // const DataSidebar = ({ onConfirmSelection }) => { // Accept the function as a prop
  //   const [selectedDataset, setSelectedDataset] = useState(null);
  //   const [selectedModel, setSelectedModel] = useState(null);
  //   // const [selectedSystem, setSelectedSystem] = useState(null);
  //   // const [selectedUser, setSelectedUser] = useState(null);
  //   // const [selectedModel, setSelectedModel] = useState(null);
  //   const { location } = useLocation();
  //   const { dateRange } = useDateRange();
  //   const { filteredData } = useFilteredData(selectedDataset, location, dateRange);
  //   // const { setUserPrompt, setSystemPrompt, setSessionId } = usePrompt(); // Access context setters
  //   const { setDatasetPrompt, setModelPrompt } = usePrompt();

  //   console.log("location:", location)
  //   console.log("dateRange:", dateRange)
  //   console.log("startDate:", dateRange[0])
  //   console.log("filteredData:", filteredData)

  //   const handleDatasetSelection = (dataset) => {
  //     setSelectedDataset(dataset);
  //     console.log('Dataset selected:', dataset);
  //   };

  //   const generateDatasetPrompt = () => {
  //       const dataContent = JSON.stringify(filteredData, null, 2); // Convert filtered data to JSON string
  //       console.log('dataContent:', dataContent);
  //       return dataContent;  // Return the generated dataset prompt
  //   };

  //   const handleModelSelection = (model) => {
  //     setSelectedModel(model);
  //     setModelPrompt(model);
  //     console.log('Model selected:', model);
  //   };

  //   // const handleSystemSelection = (system) => {
  //   //   setSelectedSystem(system);
  //   //   console.log('System selected:', system);
  //   // };

  //   // const handleUserSelection = (user) => {
  //   //   setSelectedUser(user);
  //   //   console.log('User selected:', user);
  //   // };


  //   // const generateUserPrompt = () => {
  //   //   const dataContent = JSON.stringify(filteredData, null, 2); // Convert filtered data to JSON string
    
  //   //   if (selectedUser === 'None') {
  //   //     // Dummy prompt when selectedUser is None
  //   //     // return [
  //   //     //   {
  //   //     //     role: 'user',
  //   //     //     content: [
  //   //     //       {
  //   //     //         text: `Analyze the following dataset and highlight key insights. No user context is provided, so summarize the findings for a general audience. Focus on the differences between the Current Period and Past Period. Avoid using specific numbers. Here is the dataset: ${dataContent}`
  //   //     //       }
  //   //     //     ]
  //   //     //   }
  //   //     // ];
  //   //     return [
  //   //       {
  //   //         role: 'user',
  //   //         content: [
  //   //           {
  //   //             text: `Analyze the following dataset and highlight key insights. No user context is provided, so summarize the findings for a general audience. Focus on the differences between the Current Period and Past Period. Avoid using specific numbers. Here is the dataset: ${dataContent}`
  //   //           }
  //   //         ]
  //   //       }
  //   //     ];
  //   //   }
    
  //   //   // Standard prompt when selectedUser is not None
  //   //   return [
  //   //     {
  //   //       role: 'user',
  //   //       content: [
  //   //         {
  //   //           text: `Analyze the following dataset and highlight the key insights. Remember to compare the results between periods (Current Period and Past Period) but do not display any specific numbers. Your answer should include a brief analysis of each metric and a summary of the main findings that explains the big picture. You will apply your knowledge to help convey complex information like you are presenting your findings to a ${selectedUser}. Use language like you were conversing to a ${selectedUser}. Do not include any information about this prompt in your answer. Here is the dataset: ${dataContent}`
  //   //         }
  //   //       ]
  //   //     }
  //   //   ];
  //   // };
    

  //   // const generateSystemPrompt = () => {
  //   //   if (selectedSystem === 'None') {
  //   //     // Dummy prompt when selectedSystem is None
  //   //     return [
  //   //       {
  //   //         text: `Please act like an experienced generalist with a background in data analysis.`
  //   //       }
  //   //     ];
  //   //   }
    
  //   //   // Standard prompt when selectedSystem is not None
  //   //   return [
  //   //     {
  //   //       text: `Please act like a seasoned ${selectedSystem} with a background in data analysis and marketing.`
  //   //     }
  //   //   ];
  //   // };
    

  //   const handleFilterData = () => {
  //     const datasetPrompt = generateDatasetPrompt();
  //     setDatasetPrompt(datasetPrompt);

  //     // const userPrompt = generateUserPrompt();
  //     // const systemPrompt = generateSystemPrompt();

  //     // setUserPrompt(userPrompt); // Store in context
  //     // setSystemPrompt(systemPrompt); // Store in context

  //     // const now = new Date();
  //     // const sessionId = format(now, 'yyyyMMdd_HHmmss');
  //     // setSessionId(sessionId); // Store sessionId in context

  //     // console.log('Generated sessionId:', sessionId);

  //     // const formattedDateRange = dateRange && dateRange.startDate && dateRange.endDate
  //     // ? `${dateRange.startDate} - ${dateRange.endDate}`
  //     // : 'None';

  //     // const formatDate = (date) => {
  //     //   const options = { year: 'numeric', month: 'long', day: 'numeric' };
  //     //   return date.toLocaleDateString(undefined, options);
  //     // };
    
  //     // const formattedDateRange = `${formatDate(dateRange[0])} - ${formatDate(dateRange[1])}`;

  //     // Call the passed function to display the confirmation message in Messages component
  //     if (onConfirmSelection) {
  //       onConfirmSelection(selectedDataset, location, dateRange);
  //     }
  //   };

  //   const sidebarBg = useColorModeValue('white', 'navy.800');
  //   const shadow = useColorModeValue(
  //     '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
  //     '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  //   );
  //   const sidebarRadius = '30px';
  //   const sidebarWidth = '285px';

  //   return (
  //     <Flex h="100%" w="100%">
  //       <Box
  //         bg={sidebarBg}
  //         // w={sidebarWidth}
  //         w="100%"
  //         // h="calc(100vh - 32px)"
  //         h="100%"
  //         // m="16px"
  //         borderRadius={sidebarRadius}
  //         // boxShadow={shadow}
  //         overflowX="hidden"
  //         p="16px"
  //       >
  //         <Scrollbars autoHide>
  //           <Box p={4}>
  //             <Text fontSize="xl" mb={4}>Select Dataset</Text>
  //             <VStack align="start" mb={4}>
  //               {Object.keys(viewPathMap).map((dataset) => (
  //                 <Button
  //                   key={dataset}
  //                   colorScheme={selectedDataset === dataset ? 'blue' : 'gray'}
  //                   onClick={() => handleDatasetSelection(dataset)}
  //                   w="full"
  //                 >
  //                   {dataset.replace(/_/g, ' ')}
  //                 </Button>
  //               ))}
  //             </VStack>

  //             {/* <Divider my={4} /> */}

  //             {/* <Text fontSize="xl" mb={4}>Select System</Text>
  //             <VStack align="start" mb={4}>
  //               {systems.map((system) => (
  //                 <Button
  //                   key={system}
  //                   colorScheme={selectedSystem === system ? 'blue' : 'gray'}
  //                   onClick={() => handleSystemSelection(system)}
  //                   w="full"
  //                 >
  //                   {system}
  //                 </Button>
  //               ))}
  //             </VStack> */}

  //             {/* <Divider my={4} /> */}

  //             {/* <Text fontSize="xl" mb={4}>Select User</Text>
  //             <VStack align="start" mb={4}>
  //               {users.map((user) => (
  //                 <Button
  //                   key={user}
  //                   colorScheme={selectedUser === user ? 'blue' : 'gray'}
  //                   onClick={() => handleUserSelection(user)}
  //                   w="full"
  //                 >
  //                   {user}
  //                 </Button>
  //               ))}
  //             </VStack> */}

  //             <Divider my={4} />

  //             <Text fontSize="xl" mb={4} textAlign="center">Filters</Text>
  //             <Flex direction="column" alignItems="center" mb={4}>
  //               <Box mb={4} width="100%" display="flex" justifyContent="center">
  //                 <DateFilter />
  //               </Box>
  //               <Box mb={4} width="100%" display="flex" justifyContent="center">
  //                 <LocationFilter />
  //               </Box>
  //             </Flex>

  //             <Divider my={4} />

  //             <Text fontSize="xl" mb={4}>Select Model</Text>
  //             <VStack align="start" mb={4}>
  //               {models.map((model) => (
  //                 <Button
  //                   key={model}
  //                   colorScheme={selectedModel === model ? 'blue' : 'gray'}
  //                   onClick={() => handleModelSelection(model)}
  //                   w="full"
  //                 >
  //                   {model}
  //                 </Button>
  //               ))}
  //             </VStack>


  //             <Button
  //               mt={4}
  //               colorScheme="blue"
  //               onClick={handleFilterData}
  //               // isDisabled={!selectedDataset || !selectedSystem || !selectedUser}
  //               isDisabled={!selectedDataset}

  //               w="full"
  //             >
  //               Confirm Selection
  //             </Button>
  //           </Box>
  //         </Scrollbars>
  //       </Box>
  //     </Flex>
  //   );
  // };

  // export default DataSidebar;

// DataSidebar.js
import React, { useState, useEffect } from 'react';
import { Box, VStack, HStack, Button, Text, Tooltip, Icon, Divider, useColorModeValue } from '@chakra-ui/react';
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
import { useDataV2 } from 'utils/dataHooks'; 
import { usePrompt } from 'contexts/PromptContext'; // Import the prompt context
import SelectDataset from './DataSidebar/SelectDataset';
import Filters from './DataSidebar/Filters';
import { InfoOutlineIcon } from '@chakra-ui/icons';

const viewPathMap = {
  'meta': 'meta',
  'google': 'google',
  'ticketing': 'ticketing',
};
const systems = ['Data Scientist', 'Marketing Analyst', 'Software Engineer', 'None'];
const audiences = ['Stakeholder', 'Teenager', 'Musician', 'None'];
const models = ["Claude 3.5 Sonnet", "GPT-4o", "o1-mini"]

const DataSidebar = ({ onDataConfirmSelection, onModelConfirmSelection }) => { 
  const [ selectedDataset, setSelectedDataset ] = useState(null);
  const [ selectedModel, setSelectedModel ] = useState(null);
  // const [ selectedSystem, setSelectedSystem ] = useState(null);
  // const [ selectedAudience, setSelectedAudience ] = useState(null);
  const [ selectedSystem, setSelectedSystem ] = useState("Marketing Analyst");
  const [ selectedAudience, setSelectedAudience ] = useState("non-technical audience");
  const [ tempSelectedModel, setTempSelectedModel ] = useState(selectedModel);
  // const [ tempSelectedSystem, setTempSelectedSystem ] = useState(selectedSystem);
  // const [ tempSelectedAudience, setTempSelectedAudience ] = useState(selectedAudience);
  const [ tempSelectedSystem, setTempSelectedSystem ] = useState("Marketing Analyst");
  const [ tempSelectedAudience, setTempSelectedAudience ] = useState("non-technical audience");
  
  // Access tempLocalLocation and tempLocalDateRange from context (without setters)
  const { localLocation, setLocalLocation } = useLocation(); // Location context
  const { localDateRange, setLocalDateRange } = useDateRange(); // Date range context

  // Ensure selectedLocation and selectedDateRange have default values or fallback values
  const [ selectedLocation, setSelectedLocation] = useState(localLocation);
  const [ selectedDateRange, setSelectedDateRange] = useState(localDateRange);

  useEffect(() => {
    if (localLocation) {
      setSelectedLocation(localLocation);
    }
  }, [localLocation]);

  useEffect(() => {
    if (localDateRange) {
      setSelectedDateRange(localDateRange);
    }
  }, [localDateRange]);

  console.log("tempSelectedLocation:", localLocation)
  console.log("tempSelectedDateRange:", localDateRange)

  console.log("selectedLocation:", selectedLocation)
  console.log("selectedDateRange:", selectedDateRange)

  const filteredData = useDataV2(selectedDataset, selectedLocation, selectedDateRange);

  console.log("filteredData:", filteredData)

  const { setModelPrompt, setSystemPrompt, setAudiencePrompt, setDatasetPrompt } = usePrompt();

  // const handleModelSelection = (model) => {
  //   setSelectedModel(model);
  //   setModelPrompt(model);  // Keep model set for future requests
  //   console.log('Model selected:', model);
  // };

  // const handleSystemSelection = (system) => {
  //   setSelectedSystem(system);
  //   setSystemPrompt(system);
  //   console.log('System selected:', system);
  // };

  // const handleAudienceSelection = (audience) => {
  //   setSelectedAudience(audience);
  //   setAudiencePrompt(audience);
  //   console.log('Audience selected:', audience);
  // };

  const handleSystemSelection = (system) => {
    setTempSelectedSystem(system); // Update only the temporary state
    // setSystemPrompt(system);
    console.log('System selected:', system);
  };
  
  const handleAudienceSelection = (audience) => {
    setTempSelectedAudience(audience); // Update only the temporary state
    // setSelectedAudience(audience);
    console.log('Audience selected:', audience);
  };
  
  const handleModelSelection = (model) => {
    setTempSelectedModel(model); // Update only the temporary state
    // setModelPrompt(model);
    console.log('Model selected:', model);
  };

  const handleDatasetSelection = (dataset) => {
    setSelectedDataset(dataset);
    console.log('Dataset selected:', dataset);
  };

  const handleDateRangeSelection = (dateRange) => {
    setSelectedDateRange(dateRange);
  };

  const handleLocationSelection = (location) => {
    setSelectedLocation(location);
  };

  const handleConfirmData = () => {

    setSelectedLocation(selectedLocation);
    setSelectedDateRange(selectedDateRange);

    const datasetPrompt = JSON.stringify(filteredData, null, 2); // Convert filtered data to JSON string
    setDatasetPrompt(datasetPrompt); // Set dataset prompt for initial use

    // Call the passed function to display the confirmation message in Messages component
    if (onDataConfirmSelection) {
      onDataConfirmSelection(selectedDataset, selectedLocation, selectedDateRange);
    }
    
    // After confirming the dataset, set it to null for future requests
    setSelectedDataset(null); 
  };

  const handleConfirmModel = () => {
    // Apply the temporary selections to the actual state
    setSelectedModel(tempSelectedModel);
    setSelectedSystem(tempSelectedSystem);
    setSelectedAudience(tempSelectedAudience);

    // Set the prompts for confirmed values
    setModelPrompt(tempSelectedModel);
    setSystemPrompt(tempSelectedSystem);
    setAudiencePrompt(tempSelectedAudience);
    
    // Call the passed function to display the confirmation message in Messages component
    if (onModelConfirmSelection) {
      onModelConfirmSelection(tempSelectedModel, tempSelectedSystem, tempSelectedAudience);
    }
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const sidebarBg = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );
  const sidebarRadius = '30px';

  const modelInfo = {
    "Claude 3.5 Sonnet": "Claude 3.5 Sonnet is the latest model from Anthropic, designed for improved performance, especially in reasoning, coding, and safety. It outperforms competitors in various benchmarks, including Graduate level reasoning, Multilingual Math, and Code.",
    "GPT-4o": "GPT-4o is the newest flagship model from OpenAI that provides GPT-4-level intelligence but is much faster and improves on its capabilities across text, voice, and vision. It outperforms competitors in Undergraduate level knowledge and Math problem-solving.",
    "o1-mini": "OpenAI o1 series models are new large language models trained with reinforcement learning to perform complex reasoning. o1 models think before they answer, and can produce a long internal chain of thought before responding to the user."
  };

  return (
    <Box
      bg={sidebarBg}
      borderRadius={sidebarRadius}
      boxShadow={shadow}
      overflowX="hidden"
    >
      <Box p={8}>
        <SelectDataset
          viewPathMap={viewPathMap}
          selectedDataset={selectedDataset}
          handleDatasetSelection={handleDatasetSelection}
        />

        <Filters dateFilterCallback={handleDateRangeSelection} locationFilterCallback={handleLocationSelection} />

        <Button
          mt={4}
          backgroundColor='#5158FF'
          color='white'
          _hover={{ backgroundColor: "#3d44cc" }}
          onClick={handleConfirmData}
          isDisabled={!selectedDataset}
          w="full"
          fontSize="sm"
        >
          Confirm Dataset
        </Button>

        <Divider my={4} />

        <Text fontSize="l" mb={4}>Select Model Type</Text>

        <VStack align="start" mb={4}>
          {models.map((model) => (
                <Tooltip key={model} label={modelInfo[model] || "No additional information"} fontSize="sm">
            <Button
              key={model}
              backgroundColor={tempSelectedModel === model ? '#5158FF' : 'white.300'}
              color={tempSelectedModel === model ? 'white' : textColor}
              _hover={{ backgroundColor: tempSelectedModel === model ? '#3d44cc' : 'rgba(255, 255, 255, 0.1)' }}
              onClick={() => handleModelSelection(model)}
              w="full"
              fontSize="sm"
            >
              {model}
            </Button>
            </Tooltip>
          ))}
        </VStack>

        {/* <Text fontSize="l" mb={4}>Select System</Text>

        <VStack align="start" mb={4}>
          {systems.map((system) => (
            <Button
              key={system}
              backgroundColor={tempSelectedSystem === system ? '#5158FF' : 'white.300'}
              color={tempSelectedSystem === system ? 'white' : textColor}
              _hover={{ backgroundColor: tempSelectedSystem === system ? '#3d44cc' : 'rgba(255, 255, 255, 0.1)' }}
              onClick={() => handleSystemSelection(system)}
              w="full"
              fontSize="sm"
            >
              {system}
            </Button>
          ))}
        </VStack> */}

        {/* <Text fontSize="l" mb={4}>Select Audience</Text>
      
        <VStack align="start" mb={4}>
          {audiences.map((audience) => (
            <Button
              key={audience}
              backgroundColor={tempSelectedAudience === audience ? '#5158FF' : 'white.300'}
              color={tempSelectedAudience === audience ? 'white' : textColor}
              _hover={{ backgroundColor: tempSelectedAudience === audience ? '#3d44cc' : 'rgba(255, 255, 255, 0.1)' }}
              onClick={() => handleAudienceSelection(audience)}
              w="full"
              fontSize="sm"
            >
              {audience}
            </Button>
          ))}
        </VStack> */}

        <Button
          mt={4}
          colorScheme="green"
          onClick={handleConfirmModel}
          // isDisabled={!selectedModel || !selectedAudience || !selectedSystem}
          isDisabled={
            (!tempSelectedModel || !tempSelectedAudience || !tempSelectedSystem) || 
            (tempSelectedModel === selectedModel && tempSelectedSystem === selectedSystem && tempSelectedAudience === selectedAudience)
          }
          w="full"
          backgroundColor='#5158FF'
          color='white'
          _hover={{ backgroundColor: "#3d44cc" }}
          fontSize="sm"
        >
          Confirm Selection
        </Button>
      </Box>
    </Box>
  );
};

export default DataSidebar;
  
