// PromptContext.js
// React Imports
import React, { createContext, useState, useContext } from 'react';

// Create context
const PromptContext = createContext();

// Custom hook to use the PromptContext
export const usePrompt = () => useContext(PromptContext);

// Provider component
export const PromptProvider = ({ children }) => {
    const [audiencePrompt, setAudiencePrompt] = useState(null);
    const [userPrompt, setUserPrompt] = useState(null);
    const [systemPrompt, setSystemPrompt] = useState(null);
    const [sessionId, setSessionId] = useState(null); 
    const [datasetPrompt, setDatasetPrompt] = useState(null); 
    const [modelPrompt, setModelPrompt] = useState(null); 

    return (
        <PromptContext.Provider value={{ audiencePrompt, setAudiencePrompt, userPrompt, setUserPrompt, systemPrompt, setSystemPrompt, sessionId, setSessionId, datasetPrompt, setDatasetPrompt, modelPrompt, setModelPrompt }}>
        {children}
        </PromptContext.Provider>
    );
};
