// Messages.js
import React, { useState, useEffect, useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {
  messagesRenderThumb,
  messagesRenderTrack,
  messagesRenderView,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import MessageBlock from "components/chat/MessageBlock";
import { FaCircle } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { IoPaperPlane } from "react-icons/io5";
import {
  MdOutlineMoreVert,
  MdAttachFile,
  MdOutlineImage,
  MdOutlinePerson,
  MdOutlineCardTravel,
} from "react-icons/md";
import LightningIcon from "assets/img/layout/xeus_lightning_bolt_blue.png";
import { LightningChatIconFlex } from "components/icons/Icons";
import { usePrompt } from 'contexts/PromptContext'; // Import the prompt context
import { invokeModel } from "utils/api";

export default function Messages({ confirmationMessage, messageData = [], ...props }) {
  const { status, name, onSendMessage, ...rest } = props;
  const { userPrompt, setUserPrompt, systemPrompt, setSystemPrompt } = usePrompt();
  const [newMessage, setNewMessage] = useState("");
  const [localMessageData, setLocalMessageData] = useState(messageData); // Local state to manage message data
  const [isLoading, setIsLoading] = useState(false);
  const [dotCount, setDotCount] = useState(0); // Track the number of dots
  const scrollbarsRef = useRef(null);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const inputColor = useColorModeValue("secondaryGray.700", "secondaryGray.700");
  const inputText = useColorModeValue("gray.700", "gray.100");
  const blockBg = useColorModeValue("secondaryGray.300", "navy.700");
  const userMessageBg = useColorModeValue("purple.500", "purple.500");
  const brandButton = useColorModeValue("brand.500", "brand.400");
  const bgInput = useColorModeValue(
    "linear-gradient(1.02deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%)",
    "linear-gradient(1.02deg, #111C44 50%, rgba(17, 28, 68, 0) 100%)"
  );

  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const bgList = useColorModeValue("white", "whiteAlpha.100");
  const bgShadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");

  const addConfirmationMessage = () => {
    if (confirmationMessage) {
      const newMessage = {
        isUserMessage: false,
        content: confirmationMessage,
        time: new Date(),
      };

      setLocalMessageData((prevMessages) => [...prevMessages, newMessage]);
    }
  };

  // Automatically add the confirmation message when it changes
  useEffect(() => {
    addConfirmationMessage();
  }, [confirmationMessage]);

  // Scroll to the bottom when messages update
  useEffect(() => {
    if (scrollbarsRef.current) {
      scrollbarsRef.current.scrollToBottom();
    }
  }, [localMessageData]);

  useEffect(() => {
    if (isLoading) {
      const dotInterval = setInterval(() => {
        setDotCount((prev) => (prev === 5 ? 1 : prev + 1)); // Cycle dots from 0 to 3
      }, 500); // Update dots every 500ms
  
      return () => clearInterval(dotInterval); // Cleanup on unmount or when loading finishes
    }
  }, [isLoading]);

  const handleSendMessage = async () => {
    if (newMessage.trim() !== "") {
      // Overwrite the first sentence of the userPrompt
      const updatedUserPrompt = [...userPrompt];
      const originalText = updatedUserPrompt[0].content[0].text;
      const restOfText = originalText.substring(originalText.indexOf('.') + 1).trim();
      updatedUserPrompt[0].content[0].text = newMessage + ". " + restOfText;
  
      setUserPrompt(updatedUserPrompt); // Update context with the new prompt
  
      const newMessages = [
        ...localMessageData,
        {
          isUserMessage: true,
          content: newMessage,
          time: new Date(),
        },
        // {
        //   isUserMessage: false,
        //   content: updatedUserPrompt,
        //   time: new Date(),
        // },
        // {
        //   isUserMessage: false,
        //   content: systemPrompt,
        //   time: new Date(),
        // },
        {
          isUserMessage: false,
          content: "Getting response", // Add loading message
          time: new Date(),
          isLoading: true, // Flag to identify the loading message
        },
      ];
  
      setLocalMessageData(newMessages);

      setNewMessage("");

      setIsLoading(true);
  
      try {
        const response = await invokeModel(updatedUserPrompt, systemPrompt, "beat_the_bomb"); // Call the API
        const apiResponseMessage = {
          isUserMessage: false,
          content: response["response"]["content"][0]["text"], // Assuming the response contains the data you want to display
          time: new Date(),
        };
  
        // setLocalMessageData((prevMessages) => [...prevMessages, apiResponseMessage]); // Add API response to messages

        setLocalMessageData((prevMessages) =>
          prevMessages.map((msg) =>
            msg.isLoading ? apiResponseMessage : msg
          )
        );
      } catch (error) {
        const errorMessage = {
          isUserMessage: false,
          content: `Error: ${error.message}`,
          time: new Date(),
        };
  
        // setLocalMessageData((prevMessages) => [...prevMessages, errorMessage]); // Add error message to messages

        setLocalMessageData((prevMessages) =>
          prevMessages.map((msg) =>
            msg.isLoading ? errorMessage : msg
          )
        );
      } finally {
        setIsLoading(false); // Reset loading state

      }
  
    }
  };

  const renderLoadingDots = () => {
    return '.'.repeat(dotCount); // Repeat dots based on current count
  };

  const formatTime = (time) => {
    const date = new Date(time);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  useEffect(() => {
    if (scrollbarsRef.current) {
      scrollbarsRef.current.scrollToBottom(); // Ensure it scrolls to the bottom
    }
  }, [localMessageData]); // Trigger scroll to bottom whenever messages change

  const renderMessageContent = (message) => {
    // Ensure message is a string
    const messageString = typeof message === 'string' ? message : JSON.stringify(message, null, 2);

    return messageString.split('\n').map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  const clearMessages = () => {
    setLocalMessageData([]); // Clear all messages by resetting the state to an empty array
  };

  return (
    <Box h='100%' {...rest}>
      <Flex
        px='34px'
        pb='25px'
        borderBottom='1px solid'
        borderColor={borderColor}
        align='center'>
        {/* <Avatar
          h={{ base: "40px", "2xl": "50px" }}
          w={{ base: "40px", "2xl": "50px" }}
          src={LightningIcon}
          me='16px'
        /> */}
        <LightningChatIconFlex 
          // h={{ base: "40px", "2xl": "50px" }}
          // w={{ base: "40px", "2xl": "50px" }}
          me='16px'
          color="inherit" 
          style={{ marginLeft: 0, fontSize: 30 }}
        />
        <Box>
          <Text
            color={textColor}
            fontSize={{ base: "md", md: "xl" }}
            fontWeight='700'>
            {name}
          </Text>
          <Flex align='center'>
            <Icon
              w='6px'
              h='6px'
              me='8px'
              as={FaCircle}
              color={
                status === "active"
                  ? "green.500"
                  : status === "away"
                  ? "orange.500"
                  : "red.500"
              }
            />
            <Text fontSize={{ base: "sm", md: "md" }}>
              {status === "active"
                ? "Active"
                : status === "away"
                ? "Away"
                : "Offline"}{" "}
            </Text>
          </Flex>
        </Box>
        <Flex align='center' ms='auto' />
        <Menu isOpen={isOpen1} onClose={onClose1}>
          <MenuButton onClick={onOpen1} mb='0px' me='8px'>
            <Icon
              mb='-6px'
              cursor='pointer'
              as={MdOutlineMoreVert}
              color={textColor}
              maxW='min-content'
              maxH='min-content'
              w='24px'
              h='24px'
            />
          </MenuButton>
          <MenuList
            w='150px'
            minW='unset'
            maxW='150px !important'
            border='transparent'
            backdropFilter='blur(63px)'
            bg={bgList}
            boxShadow={bgShadow}
            borderRadius='20px'
            p='15px'>
            <MenuItem
              transition='0.2s linear'
              color={textColor}
              _hover={textHover}
              p='0px'
              borderRadius='8px'
              _active={{
                bg: "transparent",
              }}
              _focus={{
                bg: "transparent",
              }}
              mb='10px'>
              <Flex align='center'>
                <Icon as={MdOutlinePerson} h='16px' w='16px' me='8px' />
                <Text fontSize='sm' fontWeight='400'>
                  Save
                </Text>
              </Flex>
            </MenuItem>
            <MenuItem
              transition='0.2s linear'
              p='0px'
              borderRadius='8px'
              color={textColor}
              _hover={textHover}
              _active={{
                bg: "transparent",
              }}
              _focus={{
                bg: "transparent",
              }}
              mb='10px'
              onClick={clearMessages}
              >
              <Flex align='center'>
                <Icon as={MdOutlineCardTravel} h='16px' w='16px' me='8px' />
                <Text fontSize='sm' fontWeight='400'>
                  Delete
                </Text>
              </Flex>
            </MenuItem>
          </MenuList>{" "}
        </Menu>
        <Icon
          cursor='pointer'
          as={FiSearch}
          color={textColor}
          w='24px'
          h='24px'
        />
      </Flex>
      <Box
        h='calc(100% - 80px)'
        px={{ base: "10px", md: "20px" }}
        pt='45px'
        pb='90px' // Add padding to prevent the last message from being covered
        position='relative'>
        <Scrollbars
          ref={scrollbarsRef} // Attach ref to Scrollbars
          autoHide
          renderTrackVertical={messagesRenderTrack}
          renderThumbVertical={messagesRenderThumb}
          renderView={messagesRenderView}>
          {/* <Flex overflow='hidden'>
            <Flex
              direction='column'
              w='100%'
              maxW={{ base: "90%", lg: "calc(100% - 80px)" }}
              boxSizing='border-box'
              minHeight="110%">
              {localMessageData.map((item, index) => (
                <MessageBlock
                  key={index}
                  // content={
                  //   item.isUserMessage ? (
                  //     item.content // Display user message content directly without quotes
                  //   ) : (
                  //     <pre>{JSON.stringify(item.content || item, null, 2)}</pre>
                  //   )
                  // }
                  content={
                    // <pre>{renderMessageContent(item.content)}</pre>  // Handle newlines here
                    // <pre>
                    //   {renderMessageContent(item.content)}
                    //   {item.isLoading && renderLoadingDots()}
                    // </pre>
                    <Box
                      maxW="80%" // Limit the message bubble width to 2/3 of the message block
                      bg={item.isUserMessage ? userMessageBg : blockBg} // Background based on message type
                      borderRadius="15px" // Rounded corners for the message bubble
                      wordBreak="break-word" // Ensure long words break to fit within the bubble
                      whiteSpace="pre-wrap" // Preserve newlines in the text, but allow it to wrap
                      textAlign="left" // Align text to the left for better readability
                    >
                      {renderMessageContent(item.content)}
                      {item.isLoading && renderLoadingDots()}
                    </Box>
                  }
                  time={formatTime(item.time || new Date())} // Format time correctly for both message types
                  side={item.isUserMessage ? "right" : "left"} // User messages on the right, system messages on the left
                  bgColor={item.isUserMessage ? userMessageBg : blockBg} // Different background for user messages
                />
              ))}
            </Flex>
          </Flex> */}
          <Flex overflow='hidden'>
  <Flex
    direction='column'
    w='100%'
    maxW={{ base: "90%", lg: "calc(100% - 80px)" }} // Adjust the max width of the entire message container
    boxSizing='border-box'
    minHeight="110%"
  >
    {localMessageData.map((item, index) => (
      <Flex
        key={index}
        justifyContent={item.isUserMessage ? "flex-end" : "flex-start"} // Align user messages to the right, system messages to the left
        mb="10px" // Add some margin between messages
      >
        <Box
          maxW="75%" // Set the max width for the bubble to be 2/3 of the message block
          bg={item.isUserMessage ? userMessageBg : blockBg} // Background based on message type
          p="25px" // Add padding for spacing inside the bubble
          borderRadius="15px" // Rounded corners for the bubble
          wordBreak="break-word" // Ensure long words break properly
          whiteSpace="pre-wrap" // Preserve newlines and wrap text
          textAlign="left" // Align text for better readability
        >
          {renderMessageContent(item.content)}
          {item.isLoading && renderLoadingDots()}
          <Text
            mt="5px" // Add a little margin-top for spacing between message and time
            fontSize="xs" // Smaller font size for the time
            color={item.isUserMessage ? "gray.700" : "gray.500"}
          >
            {formatTime(item.time || new Date())} {/* Format and display time */}
          </Text>
        </Box>
      </Flex>
    ))}
  </Flex>
</Flex>
        </Scrollbars>
        <Flex
          bg={bgInput}
          backdropFilter='blur(20px)'
          pt='10px'
          position='absolute'
          w={{ base: "calc(100% - 20px)", md: "calc(100% - 40px)" }}
          bottom='0px'>
          <InputGroup me='10px' w={{ base: "100%" }}>
            <InputRightElement
              display={{ base: "none", lg: "flex" }}
              children={
                <Flex me='70px'>
                  <IconButton
                    h='max-content'
                    w='max-content'
                    mt='28px'
                    bg='inherit'
                    borderRadius='inherit'
                    _hover='none'
                    _active={{
                      bg: "inherit",
                      transform: "none",
                      borderColor: "transparent",
                    }}
                    _focus={{
                      boxShadow: "none",
                    }}
                    icon={
                      <Icon
                        as={MdAttachFile}
                        color={inputColor}
                        w='30px'
                        h='30px'
                      />
                    }
                  />
                  <IconButton
                    h='max-content'
                    w='max-content'
                    mt='28px'
                    bg='inherit'
                    borderRadius='inherit'
                    _hover='none'
                    _active={{
                      bg: "inherit",
                      transform: "none",
                      borderColor: "transparent",
                    }}
                    _focus={{
                      boxShadow: "none",
                    }}
                    icon={
                      <Icon
                        as={MdOutlineImage}
                        color={inputColor}
                        w='30px'
                        h='30px'
                      />
                    }
                  />
                </Flex>
              }
            />
            {/* <Input
              variant='search'
              fontSize='md'
              pl={{ base: "40px !important", lg: "65px !important" }}
              pr={{
                base: "0px", 
                lg: "145px !important",
              }}
              h={{ base: "50px", lg: "70px" }}
              bg={blockBg}
              color={inputText}
              fontWeight='500'
              _placeholder={{ color: "gray.400", fontSize: "16px" }}
              borderRadius={"50px"}
              placeholder={"Write your prompt..."}
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
            /> */}
            <Input
              variant='search'
              fontSize='md'
              pl={{ base: "40px !important", lg: "65px !important" }}
              pr={{
                base: "0px", 
                lg: "145px !important",
              }}
              h={{ base: "50px", lg: "70px" }}
              bg={blockBg}
              color={inputText}
              fontWeight='500'
              _placeholder={{ color: "gray.400", fontSize: "16px" }}
              borderRadius={"50px"}
              placeholder={"Write your prompt..."}
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
            />
          </InputGroup>
          <Button
            borderRadius='50%'
            ms={{ base: "14px", lg: "auto" }}
            bg={brandButton}
            w={{ base: "50px", lg: "70px" }}
            h={{ base: "50px", lg: "70px" }}
            minW={{ base: "50px", lg: "70px" }}
            minH={{ base: "50px", lg: "70px" }}
            variant='no-hover'
            onClick={handleSendMessage}>
            <Icon
              as={IoPaperPlane}
              color='white'
              w={{ base: "18px", lg: "25px" }}
              h={{ base: "18px", lg: "25px" }}
            />
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}
