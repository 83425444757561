// // locationsList.js
// export const locationsList = {
//     "illuminarium": ["Las Vegas", "Toronto"],
//     "sloomoo_institute": ["Atlanta", "Chicago", "Houston", "New York City"],
// };

// locationsList.js
const locationsList = {
    "illuminarium": ["Las Vegas", "Toronto", "Chicago"],
    "sloomoo_institute": ["Atlanta", "Chicago", "Houston", "New York City", "Los Angeles"],
    "beat_the_bomb": ["Atlanta", "Brooklyn", "Washington"],
};

// Check if module is available (Node.js environment) and export the variable
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = locationsList;
}


// // src/variables/locationsList.js
// const locationsList = {
//     "illuminarium": ["Las Vegas", "Toronto"],
//     "sloomoo_institute": ["Atlanta", "Chicago", "Houston", "New York City"],
// };

// module.exports = locationsList;