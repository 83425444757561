const getCoordinatesFromZipcode = async (zipcode, country) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode}&region=${country.toLowerCase()}&key=${apiKey}`
    );
    const data = await response.json();
    
    if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        return { lat, lng };
    } else {
        console.log(`No location found for zipcode: ${zipcode} in ${country}`);
    }
};

const getGeolocationByLocation = async (location, country) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(location)}&key=${apiKey}`
    );
    const data = await response.json();
    
    if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        return { lat, lng };
    } else {
        console.log(`No location found for ${location} in ${country}`);
    }
};

export {
    getCoordinatesFromZipcode,
    getGeolocationByLocation
}
