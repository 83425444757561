// // OverviewolumnChartViz.js
// // React / Chakra Imports
// import {
//   Box,
//   Flex,
//   Text,
//   useColorModeValue, 
// } from '@chakra-ui/react';
// import React, { useMemo } from "react";
// // Components
// import DonutChart from 'components/charts/DonutChart';
// import Card from 'components/card/Card';
// import CardHeader from 'components/card/CardHeader';
// // Contexts
// import { useLocation } from 'contexts/LocationContext';
// import { useDateRange } from 'contexts/DateRangeContext';
// // Data Functions
// import { useFilteredData } from 'utils/dataHooks';
// import { aggregateDataByColumnV2 } from 'utils/dataFunctions';
// import { formatNumber } from 'utils/dataFormatter';
// // Motion
// import { motion } from "framer-motion";
// import { MdFormatUnderlined } from 'react-icons/md';

// const MotionFlex = motion(Flex);

// export default function DonutChartViz() {
//   const { location } = useLocation();
//   const { dateRange } = useDateRange();
//   const { currentPeriodData } = useFilteredData("tickets_attendance_date_difference", location, dateRange);
//   const textColor = useColorModeValue('secondaryGray.900', 'white');
//   const textColorChart = useColorModeValue('secondaryGray.500', 'white');

//   const columnsToSum = useMemo(() => ['total_tickets'], []);

//   const filteredData = useMemo(() => 
//     currentPeriodData
//       .filter(item => item.date_difference !== -1)
//       .sort((a, b) => {
//         const aValue = a.date;
//         const bValue = b.date;
//         return bValue - aValue; 
//       })
//   , [currentPeriodData]);

//   console.log("filteredData:", filteredData);

//   // Step 3: Aggregate the filtered data by the specified column
//   const aggregatedData = useMemo(() => {
//     const result = aggregateDataByColumnV2(filteredData, 'date_difference', columnsToSum, 'sum');
//     console.log("aggregatedData (result):", result);
    
//     if (!Array.isArray(result)) {
//       console.error("Error: aggregatedData is not an array", result);
//       return [];
//     }
//     return result;
//   }, [filteredData, columnsToSum]);

//   console.log("aggregatedData (after validation):", aggregatedData);

//   const donutChartData= useMemo(() => 
//     aggregatedData
//     .map(item => item.total_tickets ?? 0)
//     , [aggregatedData]);
  
//     console.log("donutChartData:", donutChartData);

//   const donutChartCategories = useMemo(() => 
//     aggregatedData
//     .map(item => item.date_difference ?? 0)
//     , [aggregatedData]);

//   console.log("donutChartCategories:", donutChartCategories);

// const newOptions = {
//   plotOptions: {
//     pie: {
//       donut: {
//         size: '65%'
//       }
//     }
//   },
//   labels: donutChartCategories,
//   chart: {
//     toolbar: {
//       show: false,
//   },
//   },
//   legend: {
//     show: true,
//   },
//   dataLabels: {
//     enabled: true,
//     formatter: function (val) {
//       return formatNumber(val, 'percent')
//     },
//   },
//   tooltip: {
//     theme: 'dark', // Default theme
//     style: {
//       fontSize: '14px',
//       background: '#000', // Background color of the tooltip
//       color: '#fff', // Font color of the tooltip
//     },
//   },
// };

//   return (
//     <Card
//       flexDirection="column"
//       w="100%"
//     >
//       <CardHeader mt='10px' mb='10px' ps='22px'>
// 						<MotionFlex direction='column' alignSelf='flex-start' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
// 							<Text fontSize='lg' color={textColor} fontWeight='bold' mb='0px'>
// 								Average Days
// 							</Text>
// 						</MotionFlex>
//       </CardHeader>
//       <MotionFlex
//         direction="column"
//         initial={{ opacity: 0 }} // Initial state
//         animate={{ opacity: 1 }} // Animated state
//         transition={{ duration: 1 }} // Transition duration
//         w="100%"
//         h="100%"
//       >
//       <Box minH="260px" mt="auto" mb="0px" pl="15px" w="100%">
//         <DonutChart
//           chartData={donutChartData}
//           chartOptions={newOptions}
//         />
//       </Box>
//       </MotionFlex>
//     </Card>
//   );
// }


// OverviewolumnChartViz.js
// React / Chakra Imports
import {
  Box,
  Flex,
  Text,
  useColorModeValue, 
} from '@chakra-ui/react';
import React, { useMemo } from "react";
// Components
import DonutChart from 'components/charts/DonutChart';
import Card from 'components/card/Card';
import CardHeader from 'components/card/CardHeader';
// Contexts
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
// Data Functions
import { useFilteredData } from 'utils/dataHooks';
import { formatNumber } from 'utils/dataFormatter';
// Motion
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

export default function DonutChartViz() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData } = useFilteredData("tickets_attendance_date_difference", location, dateRange);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const strokeColorChart = useColorModeValue('white', '#111c44');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const filteredData = useMemo(() => 
    currentPeriodData
      .filter(item => item.date_difference >= 0)
      .sort((a, b) => {
        const aValue = a.date;
        const bValue = b.date;
        return bValue - aValue; 
      })
  , [currentPeriodData]);

  console.log("filteredData:", filteredData);

  // Group data into categories: 0, 1, 2, 3, 4, 5, 6, 7+
  const groupData = (data) => {
    const groupedData = {};
    data.filter(item => item.date_difference).forEach(item => {
      let category = item.date_difference;
      if (category >= 7) category = '7+';
      if (!groupedData[category]) {
        groupedData[category] = 0;
      }
      groupedData[category] += item.total_tickets;
    });
    return groupedData;
  };

  const groupedData = useMemo(() => groupData(filteredData), [filteredData]);

  // Convert grouped data into the format expected by the chart
  const aggregatedData = useMemo(() => {
    return Object.keys(groupedData).map(key => ({
      date_difference: key,
      total_tickets: groupedData[key],
    }));
  }, [groupedData]);

  console.log("aggregatedData:", aggregatedData);

  const donutChartData = useMemo(() => 
    aggregatedData.map(item => item.total_tickets ?? 0),
  [aggregatedData]);

  console.log("donutChartData:", donutChartData);

  const donutChartCategories = useMemo(() => 
    aggregatedData.map(item => item.date_difference ?? 0),
  [aggregatedData]);

  console.log("donutChartCategories:", donutChartCategories);

  const customColors = ['#25008D', '#3507B6', '#4B21F3', '#5451FF', '#8296FF', '#A8BBFF', '#D2D9FF', '#E6E8FF']


  const newOptions = {
    plotOptions: {
      pie: {
        donut: {
          size: '55%'
        }
      },
    },
    labels: donutChartCategories,
    chart: {
      fontFamily: 'Poppins',
      toolbar: {
        show: false,
      },
    },
    colors: customColors,
    stroke: {
      width: 2, // Size of the borders
      // colors: ['#111c44'], // Changed color of the borders to black
      colors: [strokeColorChart],
    },
    legend: {
      show: true,
      position: 'bottom', // Position the legend at the bottom
      labels: {
        colors: textColor, // Set the legend text color to white
        // useSeriesColors: false,
        fontSize: '12px', // Set the legend text font size to 12px
      },
      horizontalAlign: 'center', // Center the legend horizontally
      markers: {
        fillColors: customColors, // Set custom colors for the legend markers
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return formatNumber(val, 'percent')
      },
    },
    tooltip: {
      theme: 'dark', // Default theme
      style: {
        fontSize: '14px',
        background: '#000', // Background color of the tooltip
        color: '#fff', // Font color of the tooltip
      },
    },
  };

  return (
    <Card
      flexDirection="column"
      w="100%"
      height="100%"
      boxShadow={shadow}
    >
      <CardHeader mt='10px' mb='10px' ps='22px'>
        <MotionFlex direction='column' alignSelf='flex-start' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
          <Text fontSize='lg' color={textColor} fontWeight='bold' mb='0px'>
            % of Attendees
          </Text>
          <Text fontSize='sm' color='gray.400' fontWeight='normal'>
            by days from purchase and event date
          </Text>
        </MotionFlex>
      </CardHeader>
      <MotionFlex
        direction="column"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
        w="100%"
        // h="100%"
      >
      <Box minH="260px" mt="auto" mb="0px" pl="15px" w="100%" h="100%">
        <DonutChart
          chartData={donutChartData}
          chartOptions={newOptions}
        />
      </Box>
      </MotionFlex>
    </Card>
  );
}
