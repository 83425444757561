import { css } from '@emotion/react';
// React / Chakra Imports
import {
  Flex,
  Box,
  Icon,
  Text,
  useColorModeValue,
  Select,
} from '@chakra-ui/react';
import React, { useMemo, useState } from "react";// Components
import GroupedBarChart from 'components/charts/GroupedBarChart';
import CardHeader from 'components/card/CardHeader';
import Card from 'components/card/Card';
import {
  columnChartOptionsOverview,
} from 'variables/charts';
// Data Functions
import { formatNumber } from "utils/dataFormatter";
import { useDataV2 } from 'utils/dataHooks';
// Icons
import { IoEllipse } from 'react-icons/io5';
// Motion
import { motion } from "framer-motion";

import { parseISO, isValid, format, startOfWeek, endOfWeek } from 'date-fns';


const MotionFlex = motion(Flex);

export default function MetricsGroupedBarChart({ location, dateRange, pastDateRange }) {
  const [selectedMetric, setSelectedMetric] = useState('spend'); // Default to 'fb_spend'
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);

  const aggregateDataByDate = (data, dateGroupBy, variables_aggregation, calculation_type) => {
    if (!Array.isArray(data)) {
      console.error("Invalid argument: data should be an array");
      return [];
    }
  
    if (!Array.isArray(variables_aggregation)) {
      console.error("Invalid argument: variables_aggregation should be an array");
      return [];
    }
  
    let groupedData = {};
  
    data.forEach(item => {
      let date;
  
      // Check if item.date is already a Date object
      if (item.date instanceof Date) {
        date = item.date;
      } else if (typeof item.date === 'string') {
        date = parseISO(item.date);  // Parse string dates
      } else {
        console.warn(`Invalid or missing date: ${item.date}`);
        return; // Skip the item if the date is not valid
      }
  
      if (!isValid(date)) {
        console.warn(`Invalid date after parsing: ${item.date}`);
        return; // Skip this item if the date is invalid after parsing
      }
  
      let groupValue;
      switch (dateGroupBy) {
        case 'day_of_week':
          groupValue = format(date, 'EEEE'); // e.g., "Monday"
          break;
        case 'day':
          groupValue = format(date, 'EEEE, MMMM d'); // e.g., "Monday, June 17"
          break;
        case 'week':
          const weekStart = startOfWeek(date);
          const weekEnd = endOfWeek(date);
          groupValue = `${format(weekStart, 'EEEE, MMMM d')} - ${format(weekEnd, 'EEEE, MMMM d')}`; // e.g., "Monday, June 17 - Sunday, June 23"
          break;
        case 'month':
          groupValue = format(date, 'MMMM, yyyy'); // e.g., "June, 2023"
          break;
        default:
          console.error("Invalid date group by value");
          return;
      }
  
      if (!groupedData[groupValue]) {
        groupedData[groupValue] = {};
        variables_aggregation.forEach(variable => {
          groupedData[groupValue][variable] = [];
        });
      }
  
      variables_aggregation.forEach(variable => {
        const value = item[variable] || 0; // Fallback to 0 if undefined or null
        groupedData[groupValue][variable].push(typeof value === 'number' ? value : 0); // Ensure the value is numeric
      });
    });
  
    const aggregatedData = Object.keys(groupedData).map(key => {
      const values = groupedData[key];
      let result = { date: key };
  
      Object.keys(values).forEach(variable => {
        const variableValues = values[variable].filter(value => value !== null && !isNaN(value)); // Filter out null or NaN values
        let aggregateValue = 0;
  
        if (variableValues.length > 0) {
          if (calculation_type === 'sum') {
            aggregateValue = variableValues.reduce((sum, value) => sum + value, 0);
          } else if (calculation_type === 'count') {
            aggregateValue = variableValues.length;
          } else if (calculation_type === 'countd') {
            aggregateValue = new Set(variableValues).size;
          } else if (calculation_type === 'average') {
            aggregateValue = variableValues.reduce((sum, value) => sum + value, 0) / variableValues.length;
          } else {
            aggregateValue = variableValues.length;
          }
        } else if (calculation_type === 'average') {
          aggregateValue = 0; // Avoid division by zero when there are no valid values
        }
  
        result[variable] = aggregateValue;
      });
  
      return result;
    });
  
    return aggregatedData;
  };

  console.log("currentPeriodData:", currentPeriodData)
  console.log("pastPeriodData:", pastPeriodData)

  // const columnsToSum = useMemo(() => ['tk_total_orders'], []);

  const labelMapping = {
    'spend': 'Spend',
    'purchases': 'Purchases',
    'purchases_value': 'Purchases Value',
    'roas': 'ROAS',
    'clicks': 'Clicks',
    'ctr': 'CTR',
    'cpm': 'CPM',
    'cpc': 'CPC',
  };

  const metricsOptions = Object.keys(labelMapping);

  const columnsToSum = useMemo(() => ['fb_spend', 'fb_impressions', 'fb_clicks', 'fb_purchases', 'fb_purchases_value'], []);

  const currentCalculatedData = useMemo(() => 
    currentPeriodData
      .map(item => ({
        ...item,
        fb_spend: item.fb_spend ?? 0,
        fb_purchases: (item.fb_purchases_7d_click ?? 0) + (item.fb_purchases_1d_view ?? 0),
        fb_purchases_value: (item.fb_purchases_value_7d_click ?? 0) + (item.fb_purchases_value_1d_view ?? 0),
        fb_roas: item.fb_spend ? ((item.fb_purchases_value_7d_click + item.fb_purchases_value_1d_view) / item.fb_spend) : 0,
        fb_clicks: item.fb_clicks ?? 0,
        fb_cpm: item.fb_impressions ? ((item.fb_spend / item.fb_impressions) * 1000) : 0,
        fb_ctr: item.fb_impressions ? ((item.fb_clicks / item.fb_impressions) * 100) : 0,
        fb_cpc: item.fb_clicks ? (item.fb_spend / item.fb_clicks) : 0,
      }))
  , [currentPeriodData]);

  const pastCalculatedData = useMemo(() => 
    pastPeriodData
      .map(item => ({
        ...item,
        fb_spend: item.fb_spend ?? 0,
        fb_purchases: (item.fb_purchases_7d_click ?? 0) + (item.fb_purchases_1d_view ?? 0),
        fb_purchases_value: (item.fb_purchases_value_7d_click ?? 0) + (item.fb_purchases_value_1d_view ?? 0),
        fb_roas: item.fb_spend ? ((item.fb_purchases_value_7d_click + item.fb_purchases_value_1d_view) / item.fb_spend) : 0,
        fb_clicks: item.fb_clicks ?? 0,
        fb_cpm: item.fb_impressions ? ((item.fb_spend / item.fb_impressions) * 1000) : 0,
        fb_ctr: item.fb_impressions ? ((item.fb_clicks / item.fb_impressions) * 100) : 0,
        fb_cpc: item.fb_clicks ? (item.fb_spend / item.fb_clicks) : 0,
      }))
  , [pastPeriodData]);

  const currentAggregatedData = useMemo(() => aggregateDataByDate(currentCalculatedData, 'day_of_week', columnsToSum, 'sum'), [currentPeriodData, columnsToSum]);
  const pastAggregatedData = useMemo(() => aggregateDataByDate(pastCalculatedData, 'day_of_week', columnsToSum, 'sum'), [pastPeriodData, columnsToSum]);

  console.log("currentAggregatedData:", currentAggregatedData)
  console.log("pastAggregatedData:", pastAggregatedData)

  const currentFilteredData = useMemo(() => {
    const metricMapping = {
      spend: 'fb_spend',
      purchases: 'fb_purchases',
      purchases_value: 'fb_purchases_value',
      roas: 'fb_roas',
      clicks: 'fb_clicks',
      cpm: 'fb_cpm',
      ctr: 'fb_ctr',
      cpc: 'fb_cpc',
    };
  
    const metricKey = metricMapping[selectedMetric];

    if (!metricKey) return [];
  
    return currentAggregatedData
  }, [currentAggregatedData, selectedMetric]);

  const pastFilteredData = useMemo(() => {
    const metricMapping = {
      spend: 'fb_spend',
      purchases: 'fb_purchases',
      purchases_value: 'fb_purchases_value',
      roas: 'fb_roas',
      clicks: 'fb_clicks',
      cpm: 'fb_cpm',
      ctr: 'fb_ctr',
      cpc: 'fb_cpc',
    };
  
    const metricKey = metricMapping[selectedMetric];

    if (!metricKey) return [];
  
    return pastAggregatedData
  }, [pastAggregatedData, selectedMetric]);

  const series = useMemo(() => {
    const metricMapping = {
      spend: 'fb_spend',
      purchases: 'fb_purchases',
      purchases_value: 'fb_purchases_value',
      roas: 'fb_roas',
      clicks: 'fb_clicks',
      cpm: 'fb_cpm',
      ctr: 'fb_ctr',
      cpc: 'fb_cpc',
    };
  
    const metricKey = metricMapping[selectedMetric];
    if (!metricKey) return [];

    const currentSeriesData = currentFilteredData.map(item => item[metricKey] || 0); // Fallback to 0 if the value is missing
    const pastSeriesData = pastFilteredData.map(item => item[metricKey] || 0);
  
    return [
      { name: 'Current Period', data: currentSeriesData },
      { name: 'Past Period', data: pastSeriesData }
    ];
  }, [currentFilteredData, pastFilteredData, selectedMetric]);


  const dayOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const columnChartCategories = useMemo(() => 
    dayOrder.filter(day => currentAggregatedData.some(item => item.date === day)),
    [currentAggregatedData]
  );

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorChart = useColorModeValue('secondaryGray.500', 'white');
  const labelColor = useColorModeValue("gray", "#A3AED0");
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const formatValue = (val, metric) => {
    switch (metric) {
      case 'spend':
        return formatNumber(val, 'dollar-K', false); // Format as dollar
      case 'purchases':
        return formatNumber(val, 'int', false); // Format as integer
      case 'purchases_value':
        return formatNumber(val, 'dollar-K', false); // Format as dollar
      case 'roas':
        return formatNumber(val, 'float-2', false); // Format as float
      case 'clicks':
        return formatNumber(val, 'int', false); // Format as integer
      case 'cpm':
        return formatNumber(val, 'dollar', false); // Format as dollar
      case 'ctr':
        return formatNumber(val, 'percent-2', false); // Format as percentage
      case 'cpc':
        return formatNumber(val, 'dollar', false); // Format as dollar
      default:
        return val;
    }
  };
  
  const dataLabelsOptions = useMemo(() => ({
    formatter: (val) => formatValue(val, selectedMetric),
  }), [selectedMetric]);


  const newOptions = {
    ...columnChartOptionsOverview,
    tooltip: {
      ...columnChartOptionsOverview.tooltip,
      x: {
        // formatter: function (value, { seriesIndex, w }) {
        //   const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
        //   const percentage = ((value / total) * 100).toFixed(2);
        //   return `${value} (${percentage}%)`;
        //   // return `${value}`;
        // }
        ...dataLabelsOptions
      }
    },
    plotOptions: {
      ...columnChartOptionsOverview.plotOptions,
      bar: {
        horizontal: false,
        borderRadius: 5,
        dataLabels: {
          position: 'top', // This will position the data labels at the end of the bars
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: [textColorChart],
        fontSize: '12px',
      },
      offsetX: 0,
      offsetY: -30,
      ...dataLabelsOptions
    },
    yaxis: {
      categories: columnChartCategories,
      show: false,
      min: 0, // Force y-axis to start at 0
      max: Math.max(...series[0].data) * 1.3,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '14px',
          fontWeight: '500',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    xaxis: {
      ...columnChartOptionsOverview.yaxis,
      categories: columnChartCategories,
      show: true,
      color: 'black',
      labels: {
        show: true,
        style: {
          colors: textColorChart,
          fontSize: '14px',
        },
      },
      axisTicks: {
        show: false,
      },
    },
    fill: {
      colors: ['#00E5D1', '#566BDC'],
    },
    legend: {
      show: false,
    }
  };

  
  return (
    <Card
      flexDirection="column"
      w="100%"
      mb={{ base: '20px', lg: '0px' }}
      boxShadow={shadow}
    >
      <CardHeader mt='10px' mb='10px' ps='22px'>
        <MotionFlex
          direction='column'
          alignSelf='flex-start'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          css={css`
            cursor: default;
            &:hover {
              cursor: default;
            }
          `}  
        >
          <Flex align='center' mt='5px' w="100%">
            {/* Dropdown for metrics selection */}
            <Select 
              mt='10px' 
              onChange={(e) => setSelectedMetric(e.target.value)} 
              value={selectedMetric} 
              flex="1.5"
              maxW="15%" // Ensure it does not grow beyond 1/5 of the total width
            >
              {metricsOptions.map(metric => (
                <option key={metric} value={metric}>
                  {labelMapping[metric]} {/* Display the label from labelMapping */}
                </option>
              ))}
            </Select>
            
            {/* Text next to the dropdown */}
            <Text 
              fontSize='sm' 
              fontWeight='normal' 
              mt='10px' 
              ml="15px" 
              color={textColor} 
              flex="3.5" // Set text to take 4/5 of the width
            >
              by day of the week
            </Text>
          </Flex>
          <Flex align='center' mt='5px'>
            <Icon as={IoEllipse} color='#00E5D1' w={4} h={4} pe='3px' />
            <Text fontSize='sm' color='gray.400' fontWeight='normal'>
              current period
            </Text>
            <Box mx={2} />
            <Icon as={IoEllipse} color='#566BDC' w={4} h={4} pe='3px' />
            <Text fontSize='sm' color='gray.400' fontWeight='normal'>
              past period
            </Text>
          </Flex>
        </MotionFlex>
      </CardHeader>
      <MotionFlex
        direction="column"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
        w="100%"
        css={css`
          cursor: default;
          &:hover {
            cursor: default;
          }
        `}
      >
        <Box
          minH="260px"
          mt="auto"
          mb="-20px"
          w="100%"
        >
          <GroupedBarChart
            chartData={series}
            chartOptions={newOptions}
            css={css`
              cursor: default;
              &:hover {
                cursor: default;
              }
            `}
          />
        </Box>
      </MotionFlex>
    </Card>
  );
}
